import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  MarketData: any[] = [];
  allMarketData = new BehaviorSubject<any>([]);
  constructor(public websocketservice: WebsocketService, public zone: NgZone) {
    this.websocketservice.DataAllMarket.subscribe((data: any) => {
      this.zone.run(() => {
        if (this.MarketData.length == 0) {
          if (data !== undefined && data != null && data.length > 0) {
            data.forEach((element: any) => {
              let bcurr = element[0].split('_');
              element.base = bcurr[0];
              element.quote = bcurr[1];
              this.MarketData.unshift(element);
            });
            this.MarketData = this.MarketData.sort((a: any, b: any) => a.fiatprice > b.fiatprice ? -1 : 1);
            this.allMarketData.next(this.MarketData);
          }
        } else if (this.MarketData.length != 0) {
          let tempMarket:any[] = [];
          data.forEach((newData: any) => {
            let acurr = newData[0].split('_');
            newData.base = acurr[0];
            newData.quote = acurr[1];
            let _mkt = this.MarketData.find((x: any) => x[0] == newData[0]);
            if (_mkt) {
              let i = this.MarketData.indexOf(_mkt);
              this.MarketData[i] = newData;
              tempMarket = this.MarketData;
            } else {
              this.MarketData.unshift(newData);
              tempMarket = this.MarketData;
            }
          });
          this.allMarketData.next(this.MarketData);
        }
      });
    });
  }
}
