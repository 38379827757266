import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.css']
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
  announcementData: any[] = [];
  isAnnouncementsLoading: boolean = false;
  colorShades: string[] = [];
  currentThemeSub: Subscription = new Subscription;
  constructor(public _httpservice: HttpserviceComponent, private route: ActivatedRoute,) {

    this.currentThemeSub = this._httpservice.ChartColor.subscribe((data: any) => {
      this.getAnnouncements();
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getAnnouncements();
  }

  generateColorShades(startColor: string, endColor: string, numShades: number): string[] {
    const startRGB = this.hexToRGB(startColor);
    const endRGB = this.hexToRGB(endColor);

    const colorShades = [];

    for (let i = 0; i < numShades; i++) {
      const ratio = i / (numShades - 1);
      const color = this.interpolateColor(startRGB, endRGB, ratio);
      const hexCode = this.RGBToHex(color);
      colorShades.push(hexCode);
    }

    return colorShades;
  }

  hexToRGB(hex: string): number[] {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ];
    } else {
      throw new Error('Invalid hex color format');
    }
  }

  interpolateColor(startRGB: number[], endRGB: number[], ratio: number): number[] {
    const color = [
      Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * ratio),
      Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * ratio),
      Math.round(startRGB[2] + (endRGB[2] - startRGB[2]) * ratio)
    ];

    return color;
  }

  RGBToHex(rgb: number[]): string {
    const hex = rgb.map(component => {
      const componentHex = component.toString(16);
      return componentHex.length === 1 ? "0" + componentHex : componentHex;
    });

    return "#" + hex.join("");
  }

  async getAnnouncements() {
    this.isAnnouncementsLoading = true;
    let result: any = await this._httpservice.requestAnnouncements().catch((err: any) => {
      this.isAnnouncementsLoading = false;
    });
    if (result == undefined || result == null) {
      return
    }
    this.isAnnouncementsLoading = false;
    if (!_.isEmpty(result)) {
      this.announcementData = result.filter((element: any) => {
        return element.Type == 'A';
      });

      const startColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-border-color")
      .trim(); // Dark color
      const endColor = this.RGBToHex(this.interpolateColor2(this.hexToRGB(startColor),  [255, 255, 255], 0.5)); // Light color
      const numShades = this.announcementData.length < 10 ? this.announcementData.length: 20// Number
      let shadeArray = this.generateColorShades(startColor, endColor, numShades);1
        this.announcementData.forEach((el: any, index:number) => {

          el.shade = shadeArray[index];
        })
    } else {
      this.announcementData = [];
    }
  }

  interpolateColor2(startRGB: number[], endRGB: number[], ratio: number): number[] {
    const lightness = startRGB[2] + (endRGB[2] - startRGB[2]) * ratio;
    const color = [
      Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * ratio),
      Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * ratio),
      Math.round(lightness)
    ];

    return color;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.currentThemeSub) {
      this.currentThemeSub.unsubscribe();
    }
  }
}
