import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { WebsocketService } from '../_httpservices/websocket.service';
import { HttpserviceComponent } from '../_httpservices/API/httpservice.component';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'currencyFormater'
})
export class CurrencyFormaterPipe implements PipeTransform {

  subscription: Subscription;
  cryptoList: any;
  fiatList: any;

  constructor(public _decimalPipe: DecimalPipe, public websocketservice: WebsocketService, public _httpservice: HttpserviceComponent) {
    this.subscription = this.websocketservice.CryptoPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        this.cryptoList = data;
      }
    });


    this.subscription = this.websocketservice.FiatPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        this.fiatList = data.rate_list;
      }
    });

  }

  transform(value: any, args?: any): any {
    let result: any;
    if (args != null && args !== undefined) {
      if (this.fiatList.find((x:any) => x.currency === args.toUpperCase())) {
        result = this._decimalPipe.transform((value.toString()), "1.0-8");
      } else if (this.cryptoList.find((x:any) => x[0] === args.toUpperCase())) {
        result = this._decimalPipe.transform((value.toString()), "1.0-8");
      }
      return result;
    }
  }
}

@NgModule({
  imports: [],
  declarations: [CurrencyFormaterPipe],
  exports: [CurrencyFormaterPipe]
})
export class CurrencyFormaterPipeModule { }

