import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number, type: string = '', timesize: string = ''): any {

    if (type == '' && timesize == '') {
      const minutes: number = Math.floor(value / 60);
      return (
        ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2)
      );
    }

    if (type == 'labeled' && timesize == '') {
      const minutes: number = Math.floor(value / 60);
      return ('00' + minutes).slice(-2) + 'm' + ' ' + ('00' + Math.floor(value - minutes * 60)).slice(-2) + 's';
    }

    if (type == '' && timesize == 'big') {
      const hours: number = Math.floor(value / 3600);
      const minutes: number = Math.floor((value % 3600) / 60);
      ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2)
    }

    if (type == 'labeled' && timesize == 'big') {
      const hours: number = Math.floor(value / 3600);
      const minutes: number = Math.floor((value % 3600) / 60);
      ('00' + hours).slice(-2) + 'h' + ' ' + ('00' + minutes).slice(-2) + 'm' + ' ' + ('00' + Math.floor(value - minutes * 60)).slice(-2) + 's';
    }

  }

}


@NgModule({
  imports: [],
  declarations: [TimeFormatPipe],
  exports: [TimeFormatPipe]
})
export class TimeFormatPipeModule { }