// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-otp-panel {
  width: 100%;
  max-width: 600px;
}
.enableform{
  margin-bottom: 10px;
}
.space {
  display: flex;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.login_form p {
  font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/accept-subaccount/accept-subaccount.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,WAAW;EAEX,aAAa;EAEb,uBAAuB;EACvB,eAAe;EAEf,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".mobile-otp-panel {\n  width: 100%;\n  max-width: 600px;\n}\n.enableform{\n  margin-bottom: 10px;\n}\n.space {\n  display: flex;\n  margin-bottom: 5px;\n  font-size: 15px;\n  font-weight: bold;\n}\n.buttons {\n  width: 100%;\n  display: -webkit-box;\n  display: flex;\n  -webkit-box-pack: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  -webkit-box-align: center;\n  align-items: center;\n}\n.login_form p {\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
