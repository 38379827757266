import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(alldata: any, others: any, args?: any): any {
      if (alldata || others) {
        const minIntegerDigits = others.substring(1, 0);
        if (minIntegerDigits > 0) {

          // console.log('-------------------');
        } else {
          return alldata;
        }
        // console.log('-------------------');
        return alldata;
      }
      



  }

}
