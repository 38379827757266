import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  settings: any;
  allSettingsSub: Subscription = new Subscription;

  constructor(public _httpservice: HttpserviceComponent
  ) {}

  ngOnInit() {

    this.allSettingsSub =  this._httpservice.AllSettings.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.settings = data;
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }
}
