// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.add-info {
width: 100%;
max-width: 600px;
}
.login_form p {
font-size: 12px !important;
}

.dropdown-menu-scroll {
    max-height: 50vh;
    overflow: auto;
  }`, "",{"version":3,"sources":["webpack://./src/app/modules/additional-information/additional-information.component.css"],"names":[],"mappings":";AACA;AACA,WAAW;AACX,gBAAgB;AAChB;AACA;AACA,0BAA0B;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":["\n.add-info {\nwidth: 100%;\nmax-width: 600px;\n}\n.login_form p {\nfont-size: 12px !important;\n}\n\n.dropdown-menu-scroll {\n    max-height: 50vh;\n    overflow: auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
