import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";
import { MarketService } from "src/app/_httpservices/ws-services/market.service";
import { LanguageService } from "src/app/_httpservices/API/language.service";
import { ModalService } from "src/app/shared/modal";

@Component({
  selector: "app-exchange",
  templateUrl: "./exchange.component.html",
  styleUrls: ["./exchange.component.css"],
})
export class ExchangeComponent implements OnInit, OnDestroy {

  mobile: any;
  DefaultCurrPair: any[] = [];
  marketServiceSub: Subscription = new Subscription();
  marketCurrPairsSub: Subscription = new Subscription();
  allSettingsSub: Subscription = new Subscription();
  pairURLSub: Subscription = new Subscription();
  routesSub: Subscription = new Subscription();
  CurrentMarketData: any;
  marketPair = {
    currency: '',
    market: ''
  }
  MarketData: any;
  curr_symbol: any;
  mainChart: string = "mchart";
  routerChecked = false
  previousPair = {
    currency: '',
    market: ''
  };

  lodash = _;
  MarketDataList: any[] = [];
  lang: any;
  marketLoading = true;
  CurrentURL: any;
  status2 = false;
  DefaultMarket: string = '';
  public innerWidth: any;
  userTab: string = "openOrder";
  viewType: string = 'Chart';
  showmarketpopup: boolean = false;
  isMarketModalOpen: boolean = false;

  constructor(
    public _httpservice: HttpserviceComponent,
    public marketService: MarketService,
    public websocketservice: WebsocketService,
    private route: ActivatedRoute,
    public router: Router,
    private translate: TranslateService,
    public languageService: LanguageService,
    public modalService: ModalService
  ) {
    // getting window with start
    this.mobile = window.innerWidth <= 768;
    window.onresize = () => {
      this.mobile = window.innerWidth <= 768;
    };
    // getting window with end



    this.languageService.language.subscribe((res: any) => {
      this.lang = res;
    });

    this.route.params.subscribe((params) => {
      if (this.router.url.split('/')[1] == 'exchange') {
        this.CurrentURL = this.router.url.split('/')[1];
        this.websocketservice.CurrURLName(this.CurrentURL);
        
        if (window.innerWidth <= 768 && this.isMarketModalOpen) {
          this.closeMarketModal();
        }

        this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
          if (!_.isEmpty(data)) {
            if (!_.isEqual(data.default_symbol, "")) {
              this.DefaultCurrPair = data.default_symbol.split("_");
              if (params['pair']) {
                let pair = params['pair'];
                if (pair.includes('-')) {
                  this.marketPair.currency = pair.split('-')[0]!;
                  this.marketPair.market = pair.split('-')[1]!;
                  this.singlePairMarket();
                  this.marketLoading = false;
                } else {
                  this.marketLoading = true;
                  this.redirectDefaultPair();
                }
              } else {
                this.marketLoading = true;
                this.redirectDefaultPair();
              }
            }
          }
        });
      } else {
        this.marketLoading = true;
        this.redirectDefaultPair();
      }
    });



  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  singlePairMarket() {
    let pairVal = false
    let found = false;
    this.marketServiceSub = this.marketService.allMarketData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.MarketData = data;
        this.MarketData.forEach((element: any) => {
          var curr = element[0].split("_");
          if (
            curr[0] == this.marketPair.currency &&
            curr[1] == this.marketPair.market
          ) {
            this.CurrentMarketData = element;
            this._httpservice.tradeValue(this.CurrentMarketData[1]);
            if (!pairVal) {
              this._httpservice.setMarketPair(this.marketPair);
              found = true
              pairVal = true;
            }
          }
        });
        if (!found) {
          this.redirectDefaultPair();
        }
      }
    });
  }

  redirectDefaultPair() {
    this.router.navigate([
      "exchange",
      `${this.DefaultCurrPair[0]}-${this.DefaultCurrPair[1]}`,
    ]);
    this.marketLoading = false;
  }

  toggleMainChart(data: any) {
    this.mainChart = data;
  }

  handleUserTab(type: string) {
    this.userTab = type;
  }

  handleTabSelect(type:string) {
    this.viewType = type;
  }

  handleOpenMarket(item: any) {
    this.DefaultMarket = item;
    this.isMarketModalOpen = true;
    this.openModal('marketModal');
  }

  closeMarketModal() {
    this.isMarketModalOpen = false;
    this.closeModal('marketModal');
  }

  openOrderFormModal(type:string) {
    this._httpservice.orderTypeSelected.next(type);
    this.openModal('orderFormModal');
  }

  closeOrderFormModal() {
    this.closeModal('orderFormModal');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  ngOnDestroy() {
    this.marketServiceSub.unsubscribe();
    if (this.allSettingsSub) this.allSettingsSub.unsubscribe();
    if (this.marketCurrPairsSub) {
      this.marketCurrPairsSub.unsubscribe();
    }
    this.routesSub.unsubscribe();
  }
}
