import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  HostBinding,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnDestroy,
  NgZone,
} from "@angular/core";
import { Subscription } from "rxjs";
import * as Enumerable from "linq-es2015";
import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";
import { OrderbookService } from "src/app/_httpservices/ws-services/orderbook.service";
import { MarketService } from "src/app/_httpservices/ws-services/market.service";
import { LanguageService } from "src/app/_httpservices/API/language.service";

@Component({
  selector: "app-orders-book",
  templateUrl: "./orders-book.component.html",
  styleUrls: ["./orders-book.component.css"],
})
export class OrdersBookComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild("Sellbookheight", { static: false })
  private myScrollContainer!: ElementRef;
  disableScrollDown = false;
  subscription: Subscription = new Subscription();
  allMarketDataSub: Subscription = new Subscription();
  sellBookDataSub: Subscription = new Subscription();
  buyBookDataSub: Subscription = new Subscription();
  currentMarketCurrencyPairsSubscription: Subscription = new Subscription();
  MarketData: any;
  Sell: any = [];
  Sellshown: any = [];
  Buyshown: any = [];
  sellMaxVolume: any;
  buyMaxVolume: any;
  el: any;
  CurrentMarket = "";
  CurrentCurrency = "";
  CurrentMarketData: any;
  @HostBinding("style.height.px")
  elHeight: number = 0;
  SellBookWidth: any;
  BuyBookWidth: any;
  SellScrolling = true;
  OldSellData: any;
  FiatUSDPrice: any;
  CryptoUSDPrice: any;
  // SelectedMarketData: any;
  AllCryptoUSD: any = [];
  AllFiatUSD: any = [];
  CurrentConvertCurr: any;
  CurrentUSDRate: any;
  CurrentRateinUSD: any;
  CurrentBTCRate: any;
  CurrentSelectedCurrencyprice: any;
  sellToBeShown: any = [];
  buyToBeShown: any = [];
  lang: any;
  pricePrecisionSub: Subscription = new Subscription();
  currentPrecision: any;
  pipePrecision: string = '1.0-8';
  langSub: Subscription;

  constructor(
    public _httpservice: HttpserviceComponent,
    public websocketservice: WebsocketService,
    public orderbookService: OrderbookService,
    public marketService: MarketService,
    public zone: NgZone,
    public languageService: LanguageService
  ) {
    this.langSub = this.languageService.language.subscribe((res:any) => {
      this.lang = res;
    });

    this.pricePrecisionSub = this._httpservice.pricePrecision.subscribe((precision: any) => {
      if (!_.isEmpty(precision)) {
        this.currentPrecision = precision;
        this.pipePrecision = `1.0-${precision.pricePrecision}`
      }
    });
  }

  trackByFnBuy(index: any, item: any) {
    return item.Rate; // or item.id
  }
  trackByFnSell(index: any, item: any) {
    return item.Rate; // or item.id
  }

  ngOnInit() {

    this.allMarketDataSub = this.marketService.allMarketData.subscribe(
      (data: any) => {
        this.zone.runOutsideAngular(() => {
          this.currentMarketCurrencyPairsSubscription =
            this._httpservice.currentMarketPair.subscribe(
              (cury: any) => {
                this.CurrentMarket = cury.market;
                this.CurrentCurrency = cury.currency;
                data.forEach((element: any) => {
                  var curr = element[0].split("_");
                  if (
                    curr[0] == this.CurrentCurrency &&
                    curr[1] == this.CurrentMarket
                  ) {
                    this.CurrentMarketData = element;
                  }
                });
              }
            );
        });
      }
    );

    this.sellBookDataSub = this.orderbookService.sellBookData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.sellToBeShown = new Map([...data].sort());
        this.sellMaxVolume = Math.max(...this.sellToBeShown.values());
        this.SellbookscrollTop();
        this.disableScrollDown = false;
      }  else{
        this.sellToBeShown = []
      }
    });

    this.buyBookDataSub = this.orderbookService.buyBookData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.buyToBeShown = new Map([...data].sort());
        this.buyMaxVolume = Math.max(...this.buyToBeShown.values());
      } else{
        this.buyToBeShown = [];
      }
    });

    // this.DataAllSellSubs = this.websocketservice.allOpenOrders.subscribe(
    //   (data: any) => {
    //     this.zone.runOutsideAngular(() => {
    //       let Sellmap = new Map();
    //       if (data !== undefined && data !== null) {
    //         if (data.hasOwnProperty("snapshot")) {
    //           if (
    //             data.snapshot !== undefined &&
    //             data.snapshot !== null &&
    //             data.snapshot.length != 0
    //           ) {
    //             data.snapshot[1].forEach((element: any) => {
    //               Sellmap.set(element[0], element[1])
    //             });
    //             this.Sellshown = Sellmap;
    //           }
    //         } else if (data.hasOwnProperty("updates")) {
    //           data.updates[1].forEach((el: any) => {
    //             // Delete
    //             if (!_.isEmpty(this.Sellshown)) {
    //               if (this.Sellshown.has(el[0]) && el[1] <= 0) {
    //                 this.Sellshown.delete(el[0]);
    //               }
    //             }
    //             // Update
    //             if (!_.isEmpty(this.Sellshown)) {
    //               if (this.Sellshown.has(el[0])) {
    //                 this.Sellshown.set(el[0], el[1])
    //               }
    //             }
    //             // Insert
    //             if (el[1] > 0) {
    //               this.Sellshown.set(el[0], el[1])
    //             }
    //           });

    //           // if (!_.isEmpty(data.updates.deleted_sell)) {
    //           //   Object.entries(data.updates.deleted_sell).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Sellshown)) {
    //           //         this.Sellshown.delete(parseFloat(key));
    //           //       }
    //           //     }
    //           //   );
    //           // }
    //           // if (!_.isEmpty(data.updates.updated_sell)) {
    //           //   Object.entries(data.updates.updated_sell).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Sellshown)) {
    //           //         if (this.Sellshown.has(parseFloat(key))) {
    //           //           this.Sellshown.set(parseFloat(key), value)
    //           //         }
    //           //       }
    //           //     }
    //           //   );
    //           // }
    //           // if (!_.isEmpty(data.updates.inserted_sell)) {
    //           //   Object.entries(data.updates.inserted_sell).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Sellshown)) {
    //           //         this.Sellshown.set(parseFloat(key), value);
    //           //       }
    //           //     }
    //           //   );
    //           //   this.SellbookscrollTop();
    //           //   this.disableScrollDown = false;
    //           // }
    //         }
    //         this.sellToBeShown = new Map([...this.Sellshown].sort());
    //         this.sellMaxVolume = Math.max(...this.sellToBeShown.values());
    //       }
    //     });
    //   }
    // );

    // this.DataAllBuySubs = this.websocketservice.allOpenOrders.subscribe(
    //   (data: any) => {
    //     this.zone.runOutsideAngular(() => {
    //       let Buymap = new Map();
    //       if (data !== undefined && data != null) {
    //         if (data.hasOwnProperty("snapshot")) {
    //           if (
    //             data.snapshot !== undefined &&
    //             data.snapshot !== null &&
    //             data.snapshot.length != 0
    //           ) {
    //             data.snapshot[0].forEach((element: any) => {
    //               Buymap.set(element[0], element[1])
    //             });
    //             this.Buyshown = Buymap;
    //           }
    //         } else if (data.hasOwnProperty("updates")) {
    //           if (!_.isEmpty(data.updates[0])) {
    //             data.updates[0].forEach((el: any) => {
    //               // Delete
    //               if (!_.isEmpty(this.Buyshown)) {
    //                 if (this.Buyshown.has(el[0]) && el[1] <= 0) {
    //                   this.Buyshown.delete(el[0]);
    //                 }
    //               }
    //               // Update
    //               if (!_.isEmpty(this.Buyshown)) {
    //                 if (this.Buyshown.has(el[0])) {
    //                   this.Buyshown.set(el[0], el[1])
    //                 }
    //               }
    //               // Insert
    //               if (el[1] > 0) {
    //                 this.Buyshown.set(el[0], el[1])
    //               }
    //             });
    //           }
    //           // if (!_.isEmpty(data.updates.deleted_buy)) {
    //           //   Object.entries(data.updates.deleted_buy).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Buyshown)) {
    //           //         this.Buyshown.delete(parseFloat(key));
    //           //       }
    //           //     }
    //           //   );
    //           // }
    //           // if (!_.isEmpty(data.updates.updated_buy)) {
    //           //   Object.entries(data.updates.updated_buy).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Buyshown)) {
    //           //         if (this.Buyshown.has(parseFloat(key))) {
    //           //           this.Buyshown.set(parseFloat(key), value)
    //           //         }
    //           //       }

    //           //     }
    //           //   );
    //           // }
    //           // if (!_.isEmpty(data.updates.inserted_buy)) {
    //           //   Object.entries(data.updates.inserted_buy).forEach(
    //           //     ([key, value]) => {
    //           //       if (!_.isEmpty(this.Buyshown)) {
    //           //         this.Buyshown.set(parseFloat(key), value)
    //           //       }
    //           //     }
    //           //   );
    //           // }
    //         }
    //         this.buyToBeShown = new Map([...this.Buyshown].sort((a, b) => b - a));
    //         this.buyMaxVolume = Math.max(...this.buyToBeShown.values());
    //       }
    //     });
    //   }
    // );
  }

  getOrderValue(value: any) {
    this.websocketservice.orderbookdata(value);
  }

  public onScroll() {
    const element = this.myScrollContainer.nativeElement;
    const atBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.disableScrollDown && atBottom) {
      this.disableScrollDown = false;
    } else {
      this.disableScrollDown = true;
    }
  }

  SellbookscrollTop() {
    if (this.Sellshown != null && this.Sellshown !== undefined && this.Sellshown.size != 0) {
      if (this.disableScrollDown) {
      } else {
        if (this.myScrollContainer) {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollHeight;
        }
      }
    } // i have this function for scroll down
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    event.target.innerWidth;
    // console.log(document.getElementById('buybook')!.offsetWidth)
    this.SellBookWidth = document.getElementById("sellbook")?.offsetWidth ?? 0;
    this.BuyBookWidth = document.getElementById("buybook")?.offsetWidth ?? 0;
    // console.log('asd', this.SellBookWidth, this.BuyBookWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // console.log(document.getElementById('buybook')?.offsetWidth)
      this.SellBookWidth =
        document.getElementById("sellbook")?.offsetWidth ?? 0;
      this.BuyBookWidth = document.getElementById("buybook")?.offsetWidth ?? 0;
      // console.log('fgh', this.SellBookWidth, this.BuyBookWidth);
    }, 1000);
  }

  ngAfterViewChecked() {
    this.SellbookscrollTop();
  }
  ngOnDestroy(): void {
    this.websocketservice.send({
      method: 'unsubscribe',
      channels: [
        "books-delta." + this.CurrentCurrency + '_' + this.CurrentMarket
      ]
    })
    this.allMarketDataSub.unsubscribe();
    this.sellBookDataSub.unsubscribe();
    this.buyBookDataSub.unsubscribe();

    if (this.pricePrecisionSub) {
      this.pricePrecisionSub.unsubscribe();
    }

    this.langSub.unsubscribe();
  }
}
