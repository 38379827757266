import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from '../_httpservices/API/httpservice.component';
import { WebsocketService } from '../_httpservices/websocket.service';

@Pipe({
  name: 'currconverter'
})
export class CurrconverterPipe implements PipeTransform {

  subscription: Subscription;
  CrrBTCRate: any;
  CurrRate: any;
  USDPRICELIST: any = {};

  constructor(public websocketservice: WebsocketService, public _httpservice: HttpserviceComponent) {

    this.subscription = this.websocketservice.CryptoPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        data.forEach((element: any) => {
          if (element[1] > 0) {
            this.USDPRICELIST[element[0]] = element[1];
          }
        });
      }
      this.CrrBTCRate = 1 / this.USDPRICELIST['BTC'];
    });


    this.subscription = this.websocketservice.FiatPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        data.rate_list.forEach((element: any) => {
          if (element.rate > 0 && element.currency != 'BTC') {
            this.USDPRICELIST[element.currency] = 1 / element.rate;
          }
        });
      }
    });
  }

  transform(rate: any, currencyName?: any, CurrentConvertCurr?: any): any {
    let USD_in_one_convertcurr = 1;
    let USD_Rate = this.USDPRICELIST[currencyName];
    if (USD_Rate == undefined) {
      USD_Rate = 0;
    }
    if (CurrentConvertCurr != 'USD') {
      let USD_ConvertCurr_Rate = this.USDPRICELIST[CurrentConvertCurr.trim()];
      if (USD_ConvertCurr_Rate == undefined) {
        USD_in_one_convertcurr = 0;
      } else {
        USD_in_one_convertcurr = 1 / USD_ConvertCurr_Rate;
      }

    }
    this.CurrRate = rate * (USD_Rate) * USD_in_one_convertcurr;
    return this.CurrRate;
  }
}

@NgModule({
  imports: [],
  declarations: [CurrconverterPipe],
  exports: [CurrconverterPipe]
})
export class CurrconverterPipeModule { }
