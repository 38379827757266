declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class EvpService {

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
}

GetPlans() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('evp/plans').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
GetEVPbalance(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('evp/balance/'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Subscribe(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('evp/subscribe',payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

Getsubscription(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('evp/my-subscriptions/'+payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestCancelSubscribe(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('evp/cancel-subscription',payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
}
