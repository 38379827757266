import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { GridTokenComponent } from "./gridtoken.component";
import { HttpserviceComponent } from "./httpservice.component";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class tradingbotService {
  data: any;
  subaccountLists = new BehaviorSubject<any>([]);
  showSignUp = new BehaviorSubject<boolean>(false);
  dashbordData = new BehaviorSubject<any>([]);
  loadingDashboard = new BehaviorSubject<any>(false);

constructor(
  public toastr: ToastrService,
  public auth: AuthService,
  public _gridservice: GridTokenComponent,
  public _httpservice: HttpserviceComponent,
  public router: Router
) {
}

requestGridInit() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('grid/init',).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

myStrategy(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/my-bots/' + payload,).then((res: any) => {
      this.subaccountLists.next(res);
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
strategies(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/bots', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

createStrategy(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/create-bot', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestPositions(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/positions', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestMargincalls(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/margin-calls', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestDashboard(Payload: any = '') {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/dashboard' + Payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

async getDashboard() {
  this.loadingDashboard.next(true);
  let result: any = await this.requestDashboard().catch((err: any) => {
    this.loadingDashboard.next(false);
  });

  if (result == undefined || result == null) {
    return
  }

  if (result.hasOwnProperty('Message') && result.Message == 'INVALID_SUB_ACCOUNT') {
    this.showSignUp.next(true);
    this.router.navigate(["grid-trading/signup"]);
    return;
  }
  this.loadingDashboard.next(false);
  this.showSignUp.next(false);
  this.dashbordData.next(result);
}

requestPlans(Payload: any = '') {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/plans' + Payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestStoprunning(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/stop-bot', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestSubscribe(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/subscribe', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestSubscription() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/subscriptions').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestSignup() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/sign-up').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestTelegramupdate(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/update-telegram-id', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
myStrategies() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/my-strategies').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
createmyStrategy(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/create-strategy', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
deleteStrategy(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/delete-strategy', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestPerformers(type: string = '', trader: string = '') {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/performers?type=' + type+'&trader='+trader).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestFollow(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/follow', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestUnfollow(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/unfollow', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestSymbols() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/symbols').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestWalletBalances(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('wallet/balances', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestStopBot(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/edit-bot', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestInvoices() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/invoices').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestReferralEarnings() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/referral-earnings').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestMyReferrals() {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/my-referrals').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestMydownline(payload:string = '') {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/my-downline/'+payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestCommissions(payload:string = '') {
  return new Promise((resolve, reject) => {
    this._gridservice._getApi('grid/commissions/'+payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestFundTransfer(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/transfer', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestGridMCConfig(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/gridmc-config', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestUpdateGridMC(payload: any) {
  return new Promise((resolve, reject) => {
    this._gridservice._PostApi('grid/update-gridmc', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
}
