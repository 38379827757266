declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class bankaddressService {
private _baseUrl: any;
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}



AddBankDetails(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('bank/add-bank-details', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
RequestOtpBank(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('bank/bank-details-otp', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
GetBankDetails() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('bank/list-bank-details').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestRemoveBank(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('bank/remove-bank', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
GetAddressBook(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('whitelist/list', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
AddAddressBook(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('whitelist/add', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
RequestOtpAddress(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('whitelist/otp', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
DeleteAddressBook(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('whitelist/delete', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

List_Fiat_BanksList(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('fiat/fiat-banks/'+ payload+ '/', ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
List_Fiat_Translation(lang:any,payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('fiat/translations/'+ lang, payload ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
