import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Enumerable from "linq-es2015";
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { MarketService } from 'src/app/_httpservices/ws-services/market.service';

@Component({
  selector: 'app-top-movers',
  templateUrl: './top-movers.component.html',
  styleUrls: ['./top-movers.component.css']
})
export class TopMoversComponent implements OnInit, OnDestroy {
  Markets_Name = [];
  _MarketData: any = [];
  MarketData: any = [];
  allMarketData: any = [];
  MarketDataFav: any;
  loading: any;
  SGMarketdata: any;
  FavPairs: string[] = [];
  favMarketData: any[] = [];
  DefaultMarket: any;
  DefaultMarketName = "";
  MKTName: any = [0];
  allSettingsSub: Subscription = new Subscription;
  marketsSub: Subscription = new Subscription;
  SelectedMarketData: any;
  FiatUSDPrice: any;
  CryptoUSDPrice: any;
  Topmovers: any[] = [];

  @ViewChild("Market_button", { static: false }) myDiv!: ElementRef;
  CurrentConvertCurr: string = "USD";
  fiatCurrSub: Subscription = new Subscription;
  subscription: Subscription;
  lang: any;
  public innerWidth: any;

  constructor(
    public websocketservice: WebsocketService,
    public _httpservice: HttpserviceComponent,
    private router: Router,
    public zone: NgZone,
    public marketService: MarketService,
    public languageService: LanguageService
  ) {
    this.fiatCurrSub = this._httpservice.CurrentFiatCurr.subscribe(
      (data: any) => {
        this.CurrentConvertCurr = data;
      }
    );

    this.languageService.language.subscribe((res:any) => {
      this.lang = res;
    });

    this.subscription = this.websocketservice.CryptoPrice.subscribe((data: any) => {
      this.CryptoUSDPrice = data;
      this.marketsSub = this.marketService.allMarketData.subscribe((res: any) => {
        if (!_.isEmpty(res)) {
          this.MarketData = res;
  
          // getting top movers
          this.Topmovers = Enumerable.asEnumerable(this.MarketData)
          .OrderByDescending((x: any) => {
            if (x[1] <= 0 && x[3] <= 0) {
              return;
            }
            return Math.abs(x[2])
          })
          .Take(4)
          .ToArray();

          if (this.Topmovers !== undefined && this.Topmovers != null) {
            this.Topmovers.forEach((element: any) => {
              const TopMoversCurrency = element.quote;
              if (this.CryptoUSDPrice !== undefined && this.CryptoUSDPrice != null) {
                this.CryptoUSDPrice.forEach((tax: any) => {
                  if (TopMoversCurrency === tax[0]) {
                    element[1] = parseFloat(element[1].toFixed(8)) * 1;
                    const elementTradeprice = element[1] * tax[1];
                    element.cryptoCurrencyPrice =
                      parseFloat(elementTradeprice.toFixed(8)) * 1;
                  }
                });
              }
            });
          }

        }
      });
    });


    this.allSettingsSub = this._httpservice.AllSettings.subscribe(
      (data: any) => {
        if (
          data !== undefined &&
          data != null &&
          data.markets !== undefined &&
          data.markets != null
        ) {
          const markets = data.markets.slice();
          if (data.market_groups != null && data.market_groups.length > 0) {
            //  console.log('data market_groups', data.market_groups);
            data.market_groups.forEach((element: any) => {
              if (
                element != null &&
                element.markets != null &&
                element.markets.length > 0
              ) {
                markets.push(element.label);
                //  console.log('market after group added', markets);
                element.markets.forEach((element1: any) => {
                  const index = markets.indexOf(element1);
                  if (index > -1) {
                    markets.splice(index, 1);
                  }
                });
              }
            });
          }

          this.Markets_Name = markets;
          // console.log('Default Market Name', this.Markets_Name);
          if (this.Markets_Name != null || this.Markets_Name !== undefined) {
            // console.log('----polo', this.Markets_Name[0]);
            this.MKTName = this.Markets_Name[0];
            this.DefaultMarket = "ALL";
            this.DefaultMarketName = "ALL";
            // console.log('Default Market Name' , this.MKTName) ALL;
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  GetOrders(marketdetail: any) {
    this.SelectedMarketData = marketdetail;
    if (
      marketdetail != undefined &&
      marketdetail[0] != undefined &&
      marketdetail[0] != null &&
      marketdetail[0].trim() != ""
    ) {
      this.router.navigate(['exchange', `${marketdetail.base}-${marketdetail.quote}`]);
      this.websocketservice.contractChanged(
        marketdetail.base + "_" + marketdetail.quote
      );
    }
  }

  ngOnDestroy(): void {
    this.fiatCurrSub.unsubscribe();
    this.marketsSub.unsubscribe();
    this.allSettingsSub.unsubscribe();
  }
}
