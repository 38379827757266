import {
  Component,
  OnInit,
  NgZone,
  AfterViewInit,
  OnDestroy,
  HostListener,
} from "@angular/core";

import * as Highcharts from "highcharts";

import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";
import { Subscription, from } from "rxjs";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";
import { OrderbookService } from "src/app/_httpservices/ws-services/orderbook.service";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-small-chart",
  templateUrl: "./small-chart.component.html",
  styleUrls: ["./small-chart.component.css"],
})
export class SmallChartComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  DepthChartData: any;
  SendDepthInfo = {
    symbol: "BTC_ETH",
    limit: 10,
  };
  Asks: any[] = [];
  Bids: any[] = [];
  AsksBack: any[] = [];
  BidsBack: any[] = [];
  AsksSub: Subscription = new Subscription();
  chartcolorSub: Subscription = new Subscription();
  currentMarketPairsSub: Subscription = new Subscription();
  pricePrecisionSub: Subscription;
  currentPrecision: any;
  pipePrecision: string = '';
;
  BidsSub: Subscription = new Subscription();;

  CurrentMarket = "";
  CurrentCurrency = "";

  Array: any;
  BidsItem: any;
  AsksItem: any;
  themeMode: string = "";
  themeColor: string = "";
  colchartColor: string = "";
  sell: any;
  buy: any;
  Askshown: any = {};
  Bidshown: any = {};
  AsksData = {};
  Asks_Temp: any[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  chartOptions: Highcharts.Options = {};
  askTickInterval: number = 3;
  bidTickInterval: number = 3;
  public innerWidth: any;

  constructor(
    public _httpservice: HttpserviceComponent,
    public websocketservice: WebsocketService,
    public orderbookService: OrderbookService,
    public _decimalPipe: DecimalPipe
  ) {
    const siteMode = localStorage.getItem('siteMode')!;
    if (siteMode !== null && siteMode !== "") {
      if (siteMode == "Day") {
        this.themeMode = 'var(--rx-card-bg)';
        this.themeColor = 'var(--rx-text)';
        this.colchartColor = "#cacaca";
        this.createchart();
      } else if (siteMode == "Night") {
        this.themeMode = 'var(--rx-card-bg)';
        this.themeColor = 'var(--rx-text)';
        this.colchartColor = "#000000";
        this.createchart();
      }
    }

    this.pricePrecisionSub = this._httpservice.pricePrecision.subscribe((precision: any) => {
      if (!_.isEmpty(precision)) {
        this.currentPrecision = precision;
        this.pipePrecision = `1.0-${precision.pricePrecision}`
      }
    });

    this.chartcolorSub = this._httpservice.ChartColor.subscribe((siteMode: any) => {
      this.currentMarketPairsSub = this._httpservice.currentMarketPair.subscribe((data: any) => {
        if (!_.isEmpty(data)) {
          this.CurrentMarket = data.market;
          this.CurrentCurrency = data.currency;
          if (siteMode == "Day") {
            this.themeMode = 'var(--rx-card-bg)';
            this.themeColor = 'var(--rx-text)';
            this.colchartColor = "#cacaca";
            this.createchart();
          } else if (siteMode == "Night") {
            this.themeMode = 'var(--rx-card-bg)';
            this.themeColor = 'var(--rx-text)';
            this.colchartColor = "#000000";
            this.createchart();
          }
        }
      });
    });

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  getChartdata() {
    this.AsksSub = this.orderbookService.sellBookData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        const Asks_Temp_Data: any[] = [];
        data.forEach((value: any, key: any) => {
          let tempObj = {
            Rate: key,
            Volume: value,
          };
          Asks_Temp_Data.unshift(tempObj);
        });

        Asks_Temp_Data.sort((a: any, b: any) => a.Rate - b.Rate);

        let comulativeVol = 0;
        let Asks_Temp: any[] = [];
        Asks_Temp_Data.forEach((element: any) => {
          comulativeVol += element.Volume;
          Asks_Temp.push([element.Rate, comulativeVol]);
        });
        this.Asks = Asks_Temp;
        let firstItem = this.Asks[0];
        let lastItem = this.Asks[this.Asks.length - 1];
        let gapBetween = lastItem[0] - firstItem[0];
        this.pipePrecision = `1.0-${this._httpservice.pricePrecision.value.pricePrecision}`
        this.askTickInterval = parseFloat(((this._decimalPipe.transform((gapBetween / 3), this.pipePrecision))?.toString().replace(/,/g, "")!))
    
        this.handleUpdate();
      } else {
        this.Asks = [];
        this.handleUpdate();
      }
    });

    this.BidsSub = this.orderbookService.buyBookData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        const Bids_Temp_Data: any[] = [];
        data.forEach((value: any, key: any) => {
          let tempObj = {
            Rate: key,
            Volume: value,
          };
          Bids_Temp_Data.unshift(tempObj);
        });

        Bids_Temp_Data.sort((a: any, b: any) => b.Rate - a.Rate);

        let comulativeVol = 0;
        let Bids_Temp: any[] = [];
        Bids_Temp_Data.forEach((element: any) => {
          comulativeVol += element.Volume;
          Bids_Temp.push([element.Rate, comulativeVol]);
        });

        Bids_Temp.sort(function (a: any, b: any) {
          return b[1] - a[1];
        });

        this.Bids = Bids_Temp;
        let firstItem = this.Bids[0];
        let lastItem = this.Bids[this.Bids.length - 1];
        let gapBetween = lastItem[0] - firstItem[0];
        this.pipePrecision = `1.0-${this._httpservice.pricePrecision.value.pricePrecision}`
        this.bidTickInterval = parseFloat(((this._decimalPipe.transform((gapBetween / 3), this.pipePrecision))?.toString().replace(/,/g, "")!))

        this.handleUpdate();
      } else {
        this.Bids = [];
        this.handleUpdate();
      }
    });
  }


  createchart() {
    this.chartOptions = {
      chart: {
        backgroundColor: this.themeMode,
        events: {
          load: (event) => {
            this.getChartdata();
          }
      }
      },
      series: [
        {
          type: "area",
          data: this.Bids,
          name: "Bids",
          color: "var(--success)",
          fillColor: "rgba(40, 167, 69, 0.38)",
          xAxis: 0,
        },
        {
          type: "area",
          data: this.Asks,
          name: "Asks",
          color: "var(--danger)",
          fillColor: "rgba(196, 7, 8, 0.38)",
          xAxis: 1,
        },
      ],
      xAxis: [
        {
          tickColor: "var(--rx-bg-default-fixed)",
          lineColor: "var(--rx-border-color)",
          tickInterval: this.bidTickInterval,
          showLastLabel: false,
          gridLineColor: this.themeMode,
          title: {
            text: null,
            style: {
              color: "#000000",
            },
          },
          labels: {
            style: {
              color: "var(--success)",
            },
          },
          width: '50%',
        }, {
          tickColor: "var(--rx-bg-default-fixed)",
          lineColor: "var(--rx-border-color)",
          tickInterval: this.askTickInterval,
          showFirstLabel: false,
          gridLineColor: this.themeMode,
          title: {
            text: null,
            style: {
              color: "#000000",
            },
          },
          labels: {
            style: {
              color: "var(--danger)",
            },
          },
          offset: 0,
          left: '50%',
          width: '50%'
        }
      ],
      yAxis: [{
        lineWidth: 1,
        tickColor: "var(--rx-bg-default-fixed)",
        lineColor: "var(--rx-border-color)",
        gridLineColor: this.themeMode,
        gridLineWidth: 1,
        tickPosition: 'inside',
        title: {
          text: null,
          style: {
            color: this.colchartColor,
          },
        },
        labels: {
          align: 'left',
          x: 4,
          style: {
            color: this.themeColor,
          },
        }
      }, {
        opposite: true,
        linkedTo: 0,
        lineWidth: 1,
        tickColor: "var(--rx-bg-default-fixed)",
        lineColor: "var(--rx-border-color)",
        gridLineColor: this.themeMode,
        gridLineWidth: 1,
        tickPosition: 'inside',
        title: {
          text: null,
          style: {
            color: "#000000",
          },
        },
        labels: {
          format: "{value}",
          align: "right",
          x: -4,
          style: {
            color: this.themeColor,
          },
        },
      }],
      plotOptions: {
        area: {
          fillOpacity: 0.2,
          step: "center",
          softThreshold: true,
          marker: {
            radius: 0,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
        series: {
          gapSize: 3,
          animation: false,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat:
          '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      },
    }
  }

  handleUpdate() {
    this.chartOptions.xAxis = [
      {
        categories: this.Bids.map((x) => x[0]),
      }, {
        categories: this.Asks.map((x) => x[0]),
      }
    ];

    this.chartOptions.series![0] = {
      type: "area",
      data: this.Bids,
    };

    this.chartOptions.series![1] = {
      type: "area",
      data: this.Asks,
    };

    this.chartOptions.xAxis![0] = {
      tickInterval: this.bidTickInterval,
    }

    this.chartOptions.xAxis![1] = {
      tickInterval: this.askTickInterval,
    }


    this.updateFlag = true;
  }

  ngOnDestroy() {
    if (this.BidsSub) {
      this.BidsSub.unsubscribe();
    }
    if (this.AsksSub) {
      this.AsksSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.chartcolorSub) {
      this.chartcolorSub.unsubscribe();
    }
    if (this.currentMarketPairsSub) {
      this.currentMarketPairsSub.unsubscribe();
    }
  }
}
