// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bOcRuI {
  position: absolute;
  top: 50%;
  left: 0px;
  cursor: pointer;
  transform: translateY(-50%);
  color: rgb(138, 147, 159);
  margin-left: 10px;
  text-decoration: underline;
}
.fllxxx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted rgb(248, 248, 248);
}
::ng-deep tooltip {
  max-width: calc(100vw / 2 - 50px) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/exchange/orders-form/sell/sell.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,2BAA2B;EAC3B,yBAAyB;EACzB,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,4CAA4C;AAC9C;AACA;EACE,4CAA4C;AAC9C","sourcesContent":[".bOcRuI {\n  position: absolute;\n  top: 50%;\n  left: 0px;\n  cursor: pointer;\n  transform: translateY(-50%);\n  color: rgb(138, 147, 159);\n  margin-left: 10px;\n  text-decoration: underline;\n}\n.fllxxx{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.tooltip {\n  position: relative;\n  display: inline-block;\n  border-bottom: 1px dotted rgb(248, 248, 248);\n}\n::ng-deep tooltip {\n  max-width: calc(100vw / 2 - 50px) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
