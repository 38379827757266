// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mobile-otp-panel {
  width: 100%;
  max-width: 600px;
}
.login_form p {
  font-size: 12px !important;
}

`, "",{"version":3,"sources":["webpack://./src/app/modules/official-verification/official-verification.component.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["\n.mobile-otp-panel {\n  width: 100%;\n  max-width: 600px;\n}\n.login_form p {\n  font-size: 12px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
