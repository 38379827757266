import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  enum_details = [
    {
      name: 'English',
      code: 'EN'
    },
    {
      name: '한국어',
      code: 'KO'
    },
    {
      name: '中文',
      code: 'ZH'
    },
    {
      name: 'Français',
      code: 'FR'
    },
    {
      name: 'हिन्दी',
      code: 'HI'
    },
    {
      name: 'Español',
      code: 'ES'
    },
    {
      name: 'Český',
      code: 'CS'
    },
    {
      name: 'Deutsch',
      code: 'DE'
    },
    {
      name: 'Português',
      code: 'PT'
    },
    {
      name: 'Български',
      code: 'BG'
    },
    {
      name: 'Dansk',
      code: 'DA'
    },
    {
      name: 'Русский',
      code: 'RU'
    },
    {
      name: 'Ελληνικά',
      code: 'EL'
    },
    {
      name: 'Suomalainen',
      code: 'FI'
    },
    {
      name: 'Magyar',
      code: 'HU'
    },
    {
      name: 'Italiano',
      code: 'IT'
    },
    //no - nn
    {
      name: 'Norsk',
      code: 'NN'
    },
    {
      name: 'Polski',
      code: 'PL'
    },
    {
      name: 'Svenska',
      code: 'SV'
    },
    {
      name: 'Türkçe',
      code: 'TR'
    },
    {
      name: 'Српски',
      code: 'SR'
    },
    {
      name: 'Română',
      code: 'RO'
    }
  ];

  language = new BehaviorSubject<any>({});
  constructor() {
    if(!_.isNull(localStorage.getItem('Language')) && !_.isEmpty(localStorage.getItem('Language'))) {
      let currentLang:any = JSON.parse(localStorage.getItem('Language')!);
      this.enum_details.forEach((element:any) => {
        if(element.code.toLowerCase() == currentLang?.code.toLowerCase()){
          localStorage.setItem('Language', JSON.stringify(element));
          this.language.next(element);
        }
      });
    } else {
      const langObj = {
        name: 'English',
        code: 'EN'
      }
      localStorage.setItem('Language', JSON.stringify(langObj));
      this.language.next(langObj);
    }
  }
}
