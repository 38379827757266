import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { marketService } from 'src/app/_httpservices/API/market.service';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';

@Component({
  selector: 'app-convert-small-balance',
  templateUrl: './convert-small-balance.component.html',
  styleUrls: ['./convert-small-balance.component.css']
})
export class ConvertSmallBalanceComponent implements OnInit, OnDestroy {
  settings: any;
  selectAll: boolean = false;
  dustData: any[] = [];
  allBalancesSub: Subscription;
  lang: any;
  convertAll = false;
  totalTokens: number = 0;
  convertProcessing: boolean = false;
  selectedTab: string = "convert";
  dustHistoryData: any[] = [];
  payload = {
    asset: "ALL"
  }
  lodash = _;
  currencySettingsSub: Subscription;
  currencySettings: any;
  allSettingsSub: Subscription;
  public innerWidth: any;
  dustHistoryLoading: boolean = false;
  dustDataLoading: boolean = false;

  constructor(
    public _httpservice: HttpserviceComponent,
    public market_service: marketService,
    public websocketservice: WebsocketService,
    public languageService: LanguageService,
    private translate: TranslateService,
    public router: Router,
    public rxToastrService: RxToastrService
  ) {
    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.settings = data;
        if (this.settings?.ex?.token_ticker == '' || this.settings?.ex?.token_ticker == null) {
          this.router.navigate(['/funds'])
        }
      }
    });

    this.languageService.language.subscribe((res: any) => {
      translate.setDefaultLang(res.name);
      this.translate.use(res.name);
      this.lang = res;
    });

    this.allBalancesSub = this.websocketservice.AllBalances.subscribe(data => {
      if (!_.isEmpty(data)) {
        this.getDustEstimate();
      }
    });

    this.currencySettingsSub = this._httpservice.CurrencySettings.subscribe(data => {
      if (!_.isEmpty(data)) {
        this.currencySettings = data.sort((a: any, b: any) => a.asset > b.asset || a.sort > b.sort ? 1 : -1);
        this.currencySettings.unshift({asset: 'ALL'})
      }
    });

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.innerWidth = window.innerWidth;
    this.getDustHistory();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  async getDustEstimate() {
    this.dustDataLoading = true;
    let result: any = await this.market_service.requesDustEstimate().catch((err: any) => {
      this.dustDataLoading = false;
    });
    this.dustDataLoading = false;
    if (result == undefined || result == null) {
      return
    }
    result.forEach((el:any) => {
      el.isSelected = false;
      this.totalTokens = 0;
      this.convertAll = false;
    });
    this.dustData = result;
  }

  async convertDust(item:any) {
    const payload = {
      asset: item.asset,
    }
    this.convertProcessing = true
    let result2: any = await this.market_service.requestConvertDust(payload).catch(err => {
      this.convertProcessing = false;
    });
    if (result2 == undefined || result2 == null) {
      return
    }  
    this.convertProcessing = false;
    this.rxToastrService.showRxToastr('', result2.Message, '', 'success');
    this.convertAll = false;
    this.totalTokens = 0;
    this.getDustHistory();
  }

  async getDustHistory() {
    this.dustHistoryLoading = true;
    let result: any = await this.market_service.requesDustHistory(this.payload).catch((err: any) => {
      this.dustHistoryLoading = false;
    });
    this.dustHistoryLoading = false;
    if (result == undefined || result == null) {
      return
    }
    if (_.isEmpty(result)) {
      this.dustHistoryData = [];
    } else {
      this.dustHistoryData = result;
    }

  }
  
  getSelected(item:any) {
    this.dustData.forEach((el:any) => {
      if (item.asset == el.asset) {
        if (item.isSelected) {
          this.totalTokens = this.totalTokens + parseFloat(el.tokens);
          return;
        }

        if (!item.isSelected) {
          this.totalTokens = this.totalTokens - parseFloat(el.tokens);
        }
      }
    });

    if (this.dustData.every((el:any) => el.isSelected == false)) {
      this.convertAll = false
    }

    if (this.dustData.every((el:any) => el.isSelected == true)) {
      this.convertAll = true;
    }

  }

  convert() {
    this.dustData.forEach((el:any) => {
      if (el.isSelected) {
        this.convertDust(el);
      }
    })
  }

  getAllSelected() {
    this.totalTokens = 0;
    this.dustData.forEach((el:any) => {
      if (this.convertAll) {
        el.isSelected = true;
        this.totalTokens = this.totalTokens + parseFloat(el.tokens);
        return;
      }

      if (!this.convertAll) {
        el.isSelected = false;
        this.totalTokens = 0;
      }

    });
  }

  handleTabs(type:string) {
    this.selectedTab = type;
  }

  handleAsset() {
    this.getDustHistory();
  }

  ngOnDestroy(): void {
    this.allSettingsSub.unsubscribe()
    this.currencySettingsSub.unsubscribe();
    this.allBalancesSub.unsubscribe()
    //Called once, before the instance is destroyed.
  }
}
