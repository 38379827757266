import { NgModule, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return moment(new Date(value)).fromNow();;
  }

}

@NgModule({
  declarations: [FromNowPipe],
  exports: [FromNowPipe]
})

export class FromNowPipeModule { }