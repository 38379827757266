import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { settingService } from 'src/app/_httpservices/API/setting.service';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { ModalService } from 'src/app/shared/modal';

export interface kycForm2 {
  business_name: string;
  business_type: string;
  contact_person: string;
  phone: string;
  inc_number: string;
  inc_file: string;
  tax_id: string;
  tax_file: string;
  vat_number: string;
  vat_file: string;
  Address1: string;
  Address2: string;
  Country: string;
  State: string;
  City: string;
  PostalCode: string;
  phone_ISDCode: string;
  Website: String;
  B_PPOP: boolean;
  B_PPOP_Address1: String;
  B_PPOP_Address2: String;
  B_PPOP_Country: string;
  B_PPOP_City: String;
  B_PPOP_State: String;
  B_PPOP_PostCode: String;
  vat_eligible: boolean;
  inc_date: String;
  persons: any;
}
@Component({
  selector: 'app-business-kyc',
  templateUrl: './business-kyc.component.html',
  styleUrls: ['./business-kyc.component.css'],
})
export class BusinessKycComponent implements OnInit {
  userprofile: any;
  regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  email = '';
  kycForm: kycForm2 = {
    business_name: '',
    business_type: '',
    contact_person: '',
    phone: '',
    inc_number: '',
    inc_file: '',
    tax_id: '',
    tax_file: '',
    vat_number: '',
    vat_file: '',
    Address1: '',
    Address2: '',
    Country: '',
    State: '',
    City: '',
    PostalCode: '',
    phone_ISDCode: '',
    Website: '',
    B_PPOP: false,
    B_PPOP_Address1: '',
    B_PPOP_Address2: '',
    B_PPOP_Country: '',
    B_PPOP_City: '',
    B_PPOP_State: '',
    B_PPOP_PostCode: '',
    vat_eligible: true,
    inc_date: '',
    persons: [],
  };
  person = {
    person_type: "",
    name: "",
    dob: "",
    email: "",
    phone: "",
    nationality: "",
    holding: "",
    id_doc: "",
    id_doc_file: ""
  };

  showGeneral = true;
  showPersons = false;
  countrylist: any;
  isdlist: any[] = [];

  errorBname = '';
  errorBtype = '';
  errorBisd = '';
  errorBmail = '';
  errorBphone = '';
  errorBinc = '';
  errorBincFile = '';
  errorBtax = '';
  errorBtaxFile = '';
  errorBvat = '';
  errorBadd1 = '';
  errorBcountry = '';
  errorBstate = '';
  errorBcity = '';
  errorBcode = '';
  errorBpopadd1 = '';
  errorBpopcountry = '';
  errorBpopstate = '';
  errorBpopcity = '';
  errorBpopcode = '';
  errorBvatFile = '';
  errorBdoi = '';

  errorPType = "";
  errorDname = '';
  errorDdob = '';
  errorDmail = '';
  errorDphone = '';
  errorDnation = '';
  errorDhold = "";
  errorDdoc = '';
  errorDdocFile = '';

  Persons_processing = false;
  Dkeyname = '';
  Dfile = '';
  incFile = '';
  taxFile = '';
  vatFile = '';
  incKeyname = '';
  Taxkeyname = '';
  Vatkeyname = '';
  general_processing = false;
  Final_processing = false;
  showReview = false;
  direct = true;
  sHold = true;
  date: any = Date;
  tncpayload = {
    Doc1: '',
    Doc2: '',
    Doc3: '',
  };
  errordoc1File = '';
  errordoc2File = '';
  errordoc3File = '';
  Doc1keyname = '';
  Doc2keyname = '';
  Doc3keyname = '';
  tnc_processing = false;
  enums: any;
  Bpop1 = '';
  Bpop2 = 'false';
  vat1 = 'true';
  vat2 = '';
  activity: any[] = [];
  activityProcess = false;
  status: any;
  rejectReason: any;

  lodash = _;
  BvatFileDetails: string = '';
  loadingBvatFile: boolean = false;
  BincFileDetails: string = '';
  loadingBincFile: boolean = false;
  BtaxFileDetails: string = '';
  loadingBtaxFile: boolean = false;
  DrFileDetails: string = '';
  loadingDrFile: boolean = false;
  public innerWidth: any;
  parseFloat = parseFloat;
  personTypeList: any[] = [];
  businessTypesArray: string[] = [];

  constructor(
    private _httpservice: HttpserviceComponent,
    public _settingservice: settingService,
    public router: Router,
    private translate: TranslateService,
    public languageService: LanguageService,
    private httpClient: HttpClient,
    public modalService: ModalService,
    public rxToastrService: RxToastrService
  ) {
    this.httpClient.get('assets/countries.json').subscribe((data: any) => {
      this.countrylist = data;
      this.countrylist.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      this.isdlist = data.slice();
      this.isdlist.sort((a: any, b: any) => (a.isdCode > b.isdCode ? 1 : -1));
    });

    this.getInfo();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.date = new Date();

    this.getEnums();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  async getInfo() {
    await this._settingservice.requestInfo().then(
      (res: any) => {
        // if(res.hasOwnProperty('status')){
        this.status = res.status2;
        this.rejectReason = res.reject_reason;

        this.email = res.email;
        this.kycForm.contact_person =
          res.contact_person == null ? '' : res.contact_person;
        this.kycForm.Address1 = res.Address1 == null ? '' : res.Address1;
        this.kycForm.Address2 = res.Address2 == null ? '' : res.Address2;
        this.kycForm.B_PPOP = res.B_PPOP == null ? false : res.B_PPOP;
        if (this.kycForm.B_PPOP) {
          this.Bpop1 = 'true';
          this.Bpop2 = '';
        } else {
          this.Bpop1 = '';
          this.Bpop2 = 'false';
        }
        this.kycForm.B_PPOP_Address1 =
          res.B_PPOP_Address1 == null ? '' : res.B_PPOP_Address1;
        this.kycForm.B_PPOP_Address2 =
          res.B_PPOP_Address2 == null ? '' : res.B_PPOP_Address2;
        this.kycForm.B_PPOP_City =
          res.B_PPOP_City == null ? '' : res.B_PPOP_City;
        this.countrylist.forEach((element: any) => {
          if (element.countryCode == res.B_PPOP_Country) {
            this.kycForm.B_PPOP_Country = element.countryCode;
          }
          if (element.countryCode == res.Country) {
            this.kycForm.Country = element.countryCode;
          }
        });
        // this.kycForm.B_PPOP_Country = res.B_PPOP_Country == null ? "": res.B_PPOP_Country;
        this.kycForm.B_PPOP_PostCode =
          res.B_PPOP_PostCode == null ? '' : res.B_PPOP_PostCode;
        this.kycForm.B_PPOP_State =
          res.B_PPOP_State == null ? '' : res.B_PPOP_State;
        this.kycForm.City = res.City == null ? '' : res.City;
        // this.kycForm.Country = res.Country == null ? "": res.Country;
        this.kycForm.PostalCode = res.PostalCode == null ? '' : res.PostalCode;
        this.kycForm.State = res.State == null ? '' : res.State;
        this.kycForm.Website = res.Website == null ? '' : res.Website;
        this.kycForm.business_name =
          res.business_name == null ? '' : res.business_name;
        this.kycForm.business_type =
          res.business_type == null ? '' : res.business_type;
        this.kycForm.inc_date = res.inc_date == null ? '' : res.inc_date;
        this.kycForm.inc_file = res.inc_file == null ? '' : res.inc_file;
        this.kycForm.inc_number = res.inc_number == null ? '' : res.inc_number;
        this.kycForm.phone = res.phone == null ? '' : res.phone;
        this.kycForm.phone_ISDCode =
          res.phone_ISDCode == null ? '' : res.phone_ISDCode;
        this.kycForm.tax_file = res.tax_file == null ? '' : res.tax_file;
        this.kycForm.tax_id = res.tax_id == null ? '' : res.tax_id;
        this.kycForm.vat_eligible =
          res.vat_eligible == null ? false : res.vat_eligible;
        if (this.kycForm.vat_eligible) {
          this.vat1 = 'true';
          this.vat2 = '';
        } else {
          this.vat2 = 'false';
          this.vat1 = '';
        }
        this.kycForm.vat_file = res.vat_file == null ? '' : res.vat_file;
        this.kycForm.vat_number = res.vat_number == null ? '' : res.vat_number;
        this.BincFileDetails = this.kycForm.inc_file == null ? '' : this.kycForm.inc_file;
        this.incKeyname = this.kycForm.inc_file == null ? '' : this.kycForm.inc_file;
        this.BtaxFileDetails = this.kycForm.tax_file == null ? '' : this.kycForm.tax_file;
        this.Taxkeyname = this.kycForm.tax_file == null ? '' : this.kycForm.tax_file;
        this.BvatFileDetails = this.kycForm.vat_file == null ? '' : this.kycForm.vat_file;
        this.Vatkeyname = this.kycForm.vat_file == null ? '' : this.kycForm.vat_file;
        this.kycForm.persons = res.persons == null ? [] : res.persons;
        this.handleBtype();
      },
      (err: any) => {}
    );
  }

  async getEnums() {
    await this._settingservice.getEnums().then(
      (res: any) => {
        if (!_.isEmpty(res)) {
          this.businessTypesArray = [];
          this.enums = res;
          this.businessTypesArray = Object.values(res.kyb_businss_type);
          this.handleBtype();
        }
      },
      (err: any) => {}
    );
  }

  handleBtype() {
    this.person.person_type = "";
    this.personTypeList = [];

    if (this.kycForm.business_type == 'Sole_Proprietorship') {
      this.person.holding = "100";
    }

    if (this.kycForm.business_type == 'Sole_Proprietorship' || this.kycForm.business_type == 'Partnership' || this.kycForm.business_type == 'LLP') {
      if (!_.isEmpty(this.enums)) {
        this.personTypeList =  Object.values(this.enums?.kybperson_type).filter((element: any) => element == "Shareholder");
      }
    } else {
      if (!_.isEmpty(this.enums)) {
        this.personTypeList = Object.values(this.enums?.kybperson_type);
      }
    }
  }

  select(data: any) {
    if (data == 'business') {
      this.showGeneral = true;
      this.showPersons = false;
      this.showReview = false;
    }
    if (data == 'persons') {
      this.showGeneral = false;
      this.showPersons = true;
      this.showReview = false;
    }

    if (data == 'review') {
      this.showGeneral = false;
      this.showPersons = false;
      this.showReview = true;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateBname(): boolean {
    if (_.isEqual(this.kycForm.business_name.trim(), '')) {
      this.errorBname = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBname = '';
      return true;
    }
  }
  validateBtype(): boolean {
    if (_.isEqual(this.kycForm.business_type.trim(), '')) {
      this.errorBtype = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBtype = '';
      return true;
    }
  }
  validateBisd(): boolean {
    if (_.isEqual(this.kycForm.phone_ISDCode.trim(), '')) {
      this.errorBisd = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBisd = '';
      return true;
    }
  }
  // validateBmail():boolean{
  //   if (_.isEqual(this.kycForm.email.trim(), '')) {
  //     this.errorBmail = this.translate.instant('common.field_mandatory');
  //     return false;
  //   } else if (this.regex.test(this.kycForm.email) === false) {
  //     this.errorBmail = this.translate.instant('common.field_mandatory');
  //     return false;
  //   } else {
  //     this.errorBmail = ""
  //     return true;
  //   }
  // }
  validateBphone(): boolean {
    if (_.isEqual(this.kycForm.phone.trim(), '')) {
      this.errorBphone = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBphone = '';
      return true;
    }
  }
  validateBadd1(): boolean {
    if (_.isEqual(this.kycForm.Address1.trim(), '')) {
      this.errorBadd1 = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBadd1 = '';
      return true;
    }
  }

  validateBcountry(): boolean {
    if (_.isEqual(this.kycForm.Country.trim(), '')) {
      this.errorBcountry = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBcountry = '';
      return true;
    }
  }
  validateBstate(): boolean {
    if (_.isEqual(this.kycForm.State.trim(), '')) {
      this.errorBstate = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBstate = '';
      return true;
    }
  }
  validateBcity(): boolean {
    if (_.isEqual(this.kycForm.City.trim(), '')) {
      this.errorBcity = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBcity = '';
      return true;
    }
  }
  validateBcode(): boolean {
    if (_.isEqual(this.kycForm.PostalCode.trim(), '')) {
      this.errorBcode = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBcode = '';
      return true;
    }
  }
  validateBpop(event: any) {
    let Bpop = event.target.value;
    // console.log(Bpop)
    if (Bpop == 'true') {
      this.kycForm.B_PPOP = true;
      this.Bpop1 = 'true';
      this.Bpop2 = '';
    } else {
      this.kycForm.B_PPOP = false;
      this.Bpop2 = 'false';
      this.Bpop1 = '';
    }
    // console.log(this.kycForm.B_PPOP)
  }

  validateBpopadd1(): boolean {
    if (_.isEqual(this.kycForm.B_PPOP_Address1.trim(), '')) {
      this.errorBpopadd1 = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBpopadd1 = '';
      return true;
    }
  }

  validateBpopcountry(): boolean {
    if (_.isEqual(this.kycForm.B_PPOP_Country.trim(), '')) {
      this.errorBpopcountry = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBpopcountry = '';
      return true;
    }
  }
  validateBpopstate(): boolean {
    if (_.isEqual(this.kycForm.B_PPOP_State.trim(), '')) {
      this.errorBpopstate = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBpopstate = '';
      return true;
    }
  }
  validateBpopcity(): boolean {
    if (_.isEqual(this.kycForm.B_PPOP_City.trim(), '')) {
      this.errorBpopcity = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBpopcity = '';
      return true;
    }
  }
  validateBpopcode(): boolean {
    if (_.isEqual(this.kycForm.B_PPOP_PostCode.trim(), '')) {
      this.errorBpopcode = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBpopcode = '';
      return true;
    }
  }
  validateVats(event: any) {
    let vat = event.target.value;
    if (vat == 'true') {
      this.kycForm.vat_eligible = true;
      this.vat1 = 'true';
      this.vat2 = '';
      this.kycForm.vat_number = '';
    } else {
      this.kycForm.vat_eligible = false;
      this.vat2 = 'false';
      this.vat1 = '';
    }
  }
  validateBdoi(): boolean {
    if (_.isEqual(this.kycForm.inc_date.trim(), '')) {
      this.errorBdoi = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBdoi = '';
      return true;
    }
  }
  async submitGeneral() {
    if (
      this.validateBisd() == false ||
      this.validateBphone() == false ||
      this.validateBname() == false ||
      this.validateBtype() == false ||
      this.validateBadd1() == false ||
      this.validateBcountry() == false ||
      this.validateBcode() == false
    ) {
      return;
    }
    if (this.kycForm.B_PPOP) {
      if (
        this.validateBpopadd1() == false ||
        this.validateBpopcountry() == false ||
        this.validateBpopcode() == false
      ) {
        return;
      }
    }
    if (this.kycForm.vat_eligible) {
      if (this.kycForm.vat_number == 'NA') {
        this.kycForm.vat_number = '';
      }
      if (this.validateVat() == false) {
        return;
      }
      // if(this.Vatkeyname == ''){
      //   if(this.validateVatFile()== false ){
      //     return;
      //   }
      // }
    } else {
      this.kycForm.vat_number = 'NA';
    }

    // console.log('yes+')
    if (
      this.validateBdoi() == false ||
      this.validateInc() == false ||
      this.validateTax() == false
    ) {
      return;
    }
    // if(this.incKeyname == ''){
    //   if(this.validateIncFile()== false ){
    //     return;
    //   }
    // }
    // if(this.Taxkeyname == ''){
    //   if(this.validateTaxFile()== false ){
    //     return;
    //   }
    // }
    this.kycForm.inc_file = this.incKeyname;
    this.kycForm.tax_file = this.Taxkeyname;
    this.kycForm.vat_file = this.Vatkeyname;
    // if(this.kycForm.business_type != 'Sole_Proprietorship' && this.kycForm.business_type != 'Partnership' && this.kycForm.business_type != 'LLP'){
    //   this.showGeneral = false;
    //   this.showDirector = true;
    //   this.showShare = false;
    //   console.log('no')
    // } else {
    //   this.showGeneral = false;
    //   this.showDirector = false;
    //   this.showShare = true;
    //   console.log('yes')
    // }
    this.general_processing = true;
    await this._settingservice.requestKYC(this.kycForm).then(
      (res: any) => {
        this.general_processing = false;
        this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
        this.getInfo();
        this.showGeneral = false;
        this.showPersons = true;
        this.showReview = false;
      },
      (err: any) => {
        this.general_processing = false;
      }
    );
  }

  // ------------------------- person code ------------------------- //
  validatePersonType(): boolean {
    if (_.isEqual(this.person.person_type.trim(), '')) {
      this.errorPType = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorPType = ""
      return true;
    }
  }

  validateDname(): boolean {
    if (_.isEqual(this.person.name.trim(), '')) {
      this.errorDname = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDname = '';
      return true;
    }
  }

  validateDdob(): boolean {
    if (_.isEqual(this.person.dob.trim(), '')) {
      this.errorDdob = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDdob = '';
      return true;
    }
  }

  validateDemail(): boolean {
    if (_.isEqual(this.person.email.trim(), '')) {
      this.errorDmail = this.translate.instant('common.field_mandatory');
      return false;
    } else if (this.regex.test(this.person.email) === false) {
      this.errorDmail = this.translate.instant('common.invalidEmail');
      return false;
    } else {
      this.errorDmail = '';
      return true;
    }
  }

  validateDphone(): boolean {
    if (_.isEqual(this.person.phone.trim(), '')) {
      this.errorDphone = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDphone = '';
      return true;
    }
  }

  validateDnation(): boolean {
    if (_.isEqual(this.person.nationality.trim(), '')) {
      this.errorDnation = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDnation = '';
      return true;
    }
  }

  validateDhold(): boolean {
    if (this.person.holding.trim() == '' || parseFloat(this.person.holding) < 0) {
      this.errorDhold = this.translate.instant('common.field_mandatory');
      return false;
    } else if (this.person.person_type == 'Shareholder' && parseFloat(this.person.holding) <= 0) {
      this.errorDhold = this.translate.instant('common.minhold');
      return false;
    } else if (parseFloat(this.person.holding) > 100) {
      this.errorDhold = this.translate.instant('common.maxhold');
      return false;
    } else {
      this.errorDhold = ""
      return true;
    }
  }
  validateDdoc(): boolean {
    if (_.isEqual(this.person.id_doc.trim(), '')) {
      this.errorDdoc = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDdoc = '';
      return true;
    }
  }
  validateDdocFile(): boolean {
    if (_.isEqual(this.Dfile.trim(), '')) {
      this.errorDdocFile = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorDdocFile = '';
      return true;
    }
  }
  async onSelectFile(event: any) {
    if (event.target.files === undefined || event.target.files == null) {
      // console.log('no file selected');
    } else if (event.target.files.length > 0) {
      const fsize = event.target.files[0].size;
      const files = Math.round(fsize / 1024);
      // The size of the file.
      if (files > 1024 * 4) {
        this.errorDdocFile = this.translate.instant('common.FileTooBig');
        this.Dfile = '';
        this.DrFileDetails = '';
      } else if (files < 1) {
        this.errorDdocFile = this.translate.instant('common.FileTooSmall');
        this.Dfile = '';
        this.DrFileDetails = '';
      } else {
        this.loadingDrFile = true;
        this.Persons_processing = true;
        const file = event.target.files[0];
        const xfilename = file.name;
        const fileType = file.type;
        // const fileExt = xfilename.split(".")[1];
        const fileExt = xfilename.match(/\.([^.]+)$/)![1];
        // console.log(fileExt)

        let res = await this._httpservice.s3fileupload(fileExt).catch((err) => {
          this.Persons_processing = false;
          this.Dfile = '';
          this.DrFileDetails = '';
        });
        if (res == undefined || res == null) {
          return;
        }

        this.putimage(res, file, fileExt, 'ID');
      }
    }
  }

  clearDrFile() {
    this.Dkeyname = '';
    this.Dfile = '';
    this.DrFileDetails = '';
    this.validateDdocFile();
  }

  async putimage(resuktt: any, file: any, ext: any, name: any) {
    let url = resuktt.signedURL;
    let res: any = await this._httpservice
      ._PutApi(url, file, ext)
      .catch((err) => {
        this.Persons_processing = false;
        this.general_processing = false;
        this.tnc_processing = false;
        if (name == 'ID') {
          this.Dkeyname = '';
          this.Dfile = '';
          this.DrFileDetails = '';
          this.loadingDrFile = false;
          this.validateDdocFile();
        }
        if (name == 'Inc') {
          this.incKeyname = '';
          this.incFile = '';
          this.BincFileDetails = '';
          this.loadingBincFile = false;
        }
        if (name == 'Tax') {
          this.Taxkeyname = '';
          this.taxFile = '';
          this.BtaxFileDetails = '';
          this.loadingBtaxFile = false;
        }
        if (name == 'Vat') {
          this.Vatkeyname = '';
          this.vatFile = '';
          this.BvatFileDetails = '';
          this.loadingBvatFile = false;
        }
        if (name == 'doc1') {
          this.Doc1keyname = '';
        }
        if (name == 'doc2') {
          this.Doc2keyname = '';
        }
        if (name == 'doc3') {
          this.Doc3keyname = '';
        }
      });
    if (res == undefined || res == null) {
      return;
    }
    this.Persons_processing = false;
    this.general_processing = false;
    this.tnc_processing = false;
    if (name == 'ID') {
      this.Dkeyname = resuktt.keyname;
      this.DrFileDetails = file.name;
      this.loadingDrFile = false;
      this.validateDdocFile();
    }
    if (name == 'Inc') {
      this.incKeyname = resuktt.keyname;
      this.BincFileDetails = file.name;
      this.loadingBincFile = false;
    }
    if (name == 'Tax') {
      this.Taxkeyname = resuktt.keyname;
      this.BtaxFileDetails = file.name;
      this.loadingBtaxFile = false;
    }
    if (name == 'Vat') {
      this.Vatkeyname = resuktt.keyname;
      this.BvatFileDetails = file.name;
      this.loadingBvatFile = false;
    }
    if (name == 'doc1') {
      this.Doc1keyname = resuktt.keyname;
    }
    if (name == 'doc2') {
      this.Doc2keyname = resuktt.keyname;
    }
    if (name == 'doc3') {
      this.Doc3keyname = resuktt.keyname;
    }
    this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
  }

  async submitPerson() {
    if (
      this.validatePersonType() == false ||
      this.validateDname() == false ||
      this.validateDdob() == false ||
      this.validateDemail() == false ||
      this.validateDphone() == false ||
      this.validateDnation() == false ||
      this.validateDhold() == false
    ) {
      return;
    }

    if ((parseFloat(this.person.holding) >= 10 && this.person.person_type == 'Shareholder') || this.person.person_type == 'Director') {
      if (this.validateDdoc() == false || this.validateDdocFile() == false) {
        return;
      }
    }

    this.person.id_doc_file = this.Dkeyname;

    if (parseFloat(this.person.holding) < 10 && this.person.person_type == 'Shareholder') {
      this.person.id_doc = "";
      this.person.id_doc_file = "";
    }

    this.kycForm.persons.push(this.person);
    this.person = {
      person_type: "",
      name: "",
      dob: "",
      email: "",
      phone: "",
      nationality: "",
      holding: "",
      id_doc: "",
      id_doc_file: ""
    };
    // this.showsecondskip = true;
    this.Dkeyname = '';
    this.Persons_processing = true;
    await this._settingservice.requestKYC(this.kycForm).then(
      (res: any) => {
        this.Persons_processing = false;
        this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
        this.getInfo();
        this.cancelPersonModal()
      },
      (err: any) => {
        this.Persons_processing = false;
        this.cancelPersonModal()
      }
    );
  }

  validateInc(): boolean {
    if (_.isEqual(this.kycForm.inc_number.trim(), '')) {
      this.errorBinc = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBinc = '';
      return true;
    }
  }
  validateIncFile(): boolean {
    if (_.isEqual(this.incFile.trim(), '')) {
      this.errorBincFile = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBincFile = '';
      return true;
    }
  }
  async BincFile(event: any) {
    if (event.target.files === undefined || event.target.files == null) {
      // console.log('no file selected');
    } else if (event.target.files.length > 0) {
      const fsize = event.target.files[0].size;
      const files = Math.round(fsize / 1024);
      // The size of the file.
      if (files > 1024 * 4) {
        this.errorDdocFile = this.translate.instant('common.FileTooBig');
        this.incFile = '';
        this.BincFileDetails = '';
      } else if (files < 1) {
        this.errorDdocFile = this.translate.instant('common.FileTooSmall');
        this.incFile = '';
        this.BincFileDetails = '';
      } else {
        this.loadingBincFile = true;
        this.general_processing = true;
        const file = event.target.files[0];
        const xfilename = file.name;
        const fileType = file.type;
        // const fileExt = xfilename.split(".")[1];
        const fileExt = xfilename.match(/\.([^.]+)$/)![1];
        // console.log(fileExt)
        let res = await this._httpservice.s3fileupload(fileExt).catch((err) => {
          this.general_processing = false;
          this.incFile = '';
          this.BincFileDetails = '';
        });
        if (res == undefined || res == null) {
          return;
        }
        this.putimage(res, file, fileExt, 'Inc');
      }
    }
  }

  clearBincFile() {
    this.incKeyname = '';
    this.incFile = '';
    this.BincFileDetails = '';
  }
  validateTax(): boolean {
    if (_.isEqual(this.kycForm.tax_id.trim(), '')) {
      this.errorBtax = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBtax = '';
      return true;
    }
  }
  validateTaxFile(): boolean {
    if (_.isEqual(this.taxFile.trim(), '')) {
      this.errorBtaxFile = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBtaxFile = '';
      return true;
    }
  }
  async BtaxFile(event: any) {
    if (event.target.files === undefined || event.target.files == null) {
      // console.log('no file selected');
    } else if (event.target.files.length > 0) {
      const fsize = event.target.files[0].size;
      const files = Math.round(fsize / 1024);
      // The size of the file.
      if (files > 1024 * 4) {
        this.errorDdocFile = this.translate.instant('common.FileTooBig');
        this.taxFile = '';
        this.BtaxFileDetails = '';
      } else if (files < 1) {
        this.errorDdocFile = this.translate.instant('common.FileTooSmall');
        this.taxFile = '';
        this.BtaxFileDetails = '';
      } else {
        this.loadingBtaxFile = true;
        this.general_processing = true;
        const file = event.target.files[0];
        const xfilename = file.name;
        const fileType = file.type;
        // const fileExt = xfilename.split(".")[1];
        const fileExt = xfilename.match(/\.([^.]+)$/)![1];
        // console.log(fileExt)
        let res = await this._httpservice.s3fileupload(fileExt).catch((err) => {
          this.general_processing = false;
          this.taxFile = '';
          this.BtaxFileDetails = '';
        });
        if (res == undefined || res == null) {
          return;
        }
        this.putimage(res, file, fileExt, 'Tax');
      }
    }
  }

  clearBtaxFile() {
    this.Taxkeyname = '';
    this.taxFile = '';
    this.BtaxFileDetails = '';
  }
  validateVat(): boolean {
    if (_.isEqual(this.kycForm.vat_number.trim(), '')) {
      this.errorBvat = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBvat = '';
      return true;
    }
  }
  validateVatFile(): boolean {
    if (_.isEqual(this.vatFile.trim(), '')) {
      this.errorBvatFile = this.translate.instant('common.field_mandatory');
      return false;
    } else {
      this.errorBvatFile = '';
      return true;
    }
  }
  async BvatFile(event: any) {
    if (event.target.files === undefined || event.target.files == null) {
      // console.log('no file selected');
    } else if (event.target.files.length > 0) {
      const fsize = event.target.files[0].size;
      const files = Math.round(fsize / 1024);
      // The size of the file.
      if (files > 1024 * 4) {
        this.errorDdocFile = this.translate.instant('common.FileTooBig');
        this.vatFile = '';
        this.BvatFileDetails = '';
      } else if (files < 1) {
        this.errorDdocFile = this.translate.instant('common.FileTooSmall');
        this.vatFile = '';
        this.BvatFileDetails = '';
      } else {
        this.loadingBvatFile = true;
        this.general_processing = true;
        const file = event.target.files[0];
        const xfilename = file.name;
        const fileType = file.type;
        // const fileExt = xfilename.split(".")[1];
        const fileExt = xfilename.match(/\.([^.]+)$/)![1];
        // console.log(fileExt)
        let res = await this._httpservice.s3fileupload(fileExt).catch((err) => {
          this.general_processing = false;
          this.vatFile = '';
          this.BvatFileDetails = '';
        });
        if (res == undefined || res == null) {
          return;
        }
        this.putimage(res, file, fileExt, 'Vat');
      }
    }
  }

  clearBvatFile() {
    this.Vatkeyname = '';
    this.vatFile = '';
    this.BvatFileDetails = '';
  }

  async deletePerson(data: any) {
    this.kycForm.persons.forEach((element: any) => {
      const index = this.kycForm.persons.indexOf(element);
      if (element.name == data.name) {
        this.kycForm.persons.splice(index, 1)
      }
    });
    await this._settingservice.requestKYC(this.kycForm).then(
      (res: any) => {
        this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
        this.getInfo();
      },
      (err: any) => {}
    );
  }

  async finalSubmit() {
    this.Final_processing = true;
    await this._settingservice.requestFinalKYC().then(
      (res: any) => {
        this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
        // this.userService.requestProfile();
        this.Final_processing = false;
        this.getInfo();
      },
      (err: any) => {
        this.Final_processing = false;
      }
    );
  }

  openPersonModal() {
    this.openModal('personModal')
  }

  cancelPersonModal() {
    this.closeModal('personModal')
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
