declare var baseUrl:any;
import {
  Injectable
} from '@angular/core';
import axios from 'axios';
// import moment from 'moment';
// import qs from 'qs';
import * as moment from 'moment';
import * as qs from 'qs';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryProviderService {
  volume: any;

  constructor(public websocketService: WebsocketService) {}

  chartData: any;
  history = {};
  lastBar: any;

  resolutions:any = {
    '1': 1,
    '5': 5,
    '15': 15,
    '60': 60,
    '240': 240,
    '1D': 1440,
    '7D': 10080,
    '30D': 43200,
  };

  historyProvider = {

    history: history,

    getBars: async (symbolInfo:any, resolution:any, from:any, to:any, first:any, limit:any) => {
      console.log('====getBars running', resolution);
      const split_symbol = symbolInfo.name.split(/[:/]/);
      const queryString = qs.stringify({
        base: split_symbol[0],
        quote: split_symbol[1],
        ts: first
          ? moment
              .unix(to)
              .subtract(1, 'm')
              .valueOf()
          : to * 1000,
        interval: this.resolutions[resolution],
        limit: limit ? limit : 1000,
      });
      // console.log(baseUrl + 'market/get-chart-data?' + queryString);
      try{
        const { data } = await axios({
          url: `${baseUrl}market/charts?${queryString}`,
          method: 'GET',
        });

        if (data.status === 'Error' || data.length === 0) {
          return [];
        }

        if (data.length) {
          // console.log(data.data);
          const bars = data.map((bar:any) => {
            delete bar.ID;

            return {
              ...bar,
              time: moment(bar.time).valueOf(),
            };
          });

          if (first) {
            const lastBar = bars[0];
            //@ts-ignore
            history[symbolInfo.name] = { lastBar };
          }
          console.log('this.resolutions[resolution]', this.resolutions[resolution]);
          this.websocketService.chartSocketSubscribe(split_symbol, this.resolutions[resolution]);
          return bars.reverse();
        }
      } catch (error){
        console.log('error', error)
      }
    }
  };
}

