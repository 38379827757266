declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class settingService {
  data: any;
  changePass = new BehaviorSubject<any>(false);

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent) {
}

openChangePassword(data:boolean) {
  this.changePass.next(data);
}


_GetLoginHistory() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('Customer/login-history').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_authstatus() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('gauth/status-2fa' ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });

}

_GAuth_Enable_Request(Bearer: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('gauth/pairing-code' ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_GAuth_Set_Enable(logdata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('gauth/enable-2fa', logdata, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_GAuth_Set_Disable(logdata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('gauth/disable-2fa', logdata, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
_Affiliate_Summary(payload:string = '') {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('affiliate/summary?'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
__My_Affiliate() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('affiliate/my').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

__My_AffiliateDownline(payload:string = '') {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('affiliate/my-downline/'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestBrokerReferrals() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('affiliate/broker-referrals').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_Affiliate_Commission(request:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('affiliate/commission?'+ request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
_getapikeylist(requestlist: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('apikeys/list', requestlist).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_GenerateApiKey(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('apikeys/generate', request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

RequestotpApiKey(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('apikeys/otp', request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_EditApiKey(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('apikeys/edit', request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_DeleteApiKey(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('apikeys/delete', request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

getSMSotp(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('phone-verification/enable-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
SMSotp(OTP: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('phone-verification/enable/' + OTP).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
disableSMSotp() {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('phone-verification/disable-otp', {}).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
SMSotpdisabled(OTP: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('phone-verification/disable/' + OTP).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

GetwebSDKtoken() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('kyc/websdk-init').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}


getWhitelistedIP() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('ip_cidr/get-ip-cidr').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestDeleteWhitelistedIP(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('ip_cidr/delete-ip-cidr', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestOTPWhitelistIP(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('ip_cidr/request-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

addWhitelistingIP(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('ip_cidr/add-ip-cidr', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestChangeFindMeStatus(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('findme/setup', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestFindMeSearch(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('findme/search', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestKYC(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('kyb/kyb', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestFinalKYC() {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('kyb/submit-kyb', '').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestemailOTP(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/change-email-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
changeEmail(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/change-email', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestmobileOTP(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/change-mobile-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
changeMobile(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/change-mobile', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestAntiphishingOTP() {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/anti-phishing-code-otp', '').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
requestAntiphishing(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/add-anti-phishing-code', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

getEnums() {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._getApi('enums')
      .then((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
  });
}
requestInfo() {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._getApi('kyb/info')
      .then((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
  });
}

requestChangeNickname(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('customer/nickname', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
