// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.rgt {
  text-align: end;
}

  .Fiat_price {
    text-align: end;
}
.simpleLoaderPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.fs4{
  font-size: 12px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/markets/markets.component.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;EAEE;IACE,eAAe;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".left {\n  justify-content: flex-start;\n}\n\n.right {\n  justify-content: flex-end;\n}\n\n.center {\n  justify-content: center;\n}\n\n.rgt {\n  text-align: end;\n}\n\n  .Fiat_price {\n    text-align: end;\n}\n.simpleLoaderPanel {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 300px;\n}\n\n.fs4{\n  font-size: 12px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
