import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class addinfoService {
  subaccountLists = new BehaviorSubject<any>([]);

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public _httpservice: HttpserviceComponent
  ) {

  }



  fieldsAns(payload: any, lang:string) {
    return new Promise((resolve, reject) => {
      this._httpservice._PostApi('customer/risk-answers/'+lang, payload).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

}
