import {
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  HttpserviceComponent
} from 'src/app/_httpservices/API/httpservice.component';

import {
  PagerService
} from 'src/app/_httpservices/pager.service';
import {
  ExcelService
} from 'service/excel.service';
import { marketService } from 'src/app/_httpservices/API/market.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/guards/auth.service';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.css']
})
export class OrdersHistoryComponent implements OnInit, OnDestroy {
  _resultbj: any = {};
  UserTradeHistory: any[] = [];
  orderHistoryXls: any[] = [];
  time = '';
  _resbj: any;
  getaddress: any;
  ordersHistory: any[] = [];
  tradesearch: any;
  orderHistory: any[] = [];
  mOrders: any;
  pair = 'ALL';

  rows = 25;
  page = 1;

  last: number = 0;
  allSettingsSub: Subscription = new Subscription;
  MarketData: any;
  loader = true;
  loading = true;
  lang: any;
  currentPairsSub: Subscription | undefined;
  pendingOrdersSub: Subscription | undefined;
  IsLogin: boolean = false;
  langSub: Subscription;
  userAuthSub: Subscription;
  public innerWidth: any;
  loadingOrdersHistory: boolean = false;

  constructor(
    public _httpservice: HttpserviceComponent,
    public market_service: marketService,
    public languageService: LanguageService,
    public websocketservice: WebsocketService,
    public authService: AuthService) {
    this.langSub = this.languageService.language.subscribe((res: any) => {
      this.lang = res;
    });
  
    this.userAuthSub = this.authService.userSubject.subscribe((data: any) => {
      this.IsLogin = !_.isNull(data) && !_.isEqual(data, "") ? true : false;
    });

    this.currentPairsSub = this._httpservice.currentMarketPair.subscribe((data: any) => {
      this.pair = `${data.currency}_${data.market}`;
      if (this.IsLogin) {
        this.GetOpenHistory();
      }

    });

    this.pendingOrdersSub = this.websocketservice.PendingOrders.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        if (this.IsLogin) {
          this.GetOpenHistory();
        }
 
      }
    });
 
    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (data.trade_setting !== undefined && data.trade_setting != null) {
        this.MarketData = data.trade_setting.sort((a: any, b: any) => a.base > b.base ? 1 : -1);
      }
    });

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  async GetOpenHistory() {
    const payload = 'side=ALL&symbol=' + this.pair + '&page=' + this.page + '&count=' + this.rows;
    this.loadingOrdersHistory = true;
    let res: any = await this.market_service.GetUserOrderHistory(payload).catch(err => {
      this.loading = false;
      this.loadingOrdersHistory = false;
    });
    if (res == undefined || res == null) {
      return
    }
    this.loadingOrdersHistory = false;
    res.rows.forEach((element: any) => {
      element.base = element.symbol.split('_')[0];
      element.quote = element.symbol.split('_')[1];
    });
    this.ordersHistory = res.rows;
  }

  trackByFnOrderHistroy(index: any, item: any) {
    return item.order_id; // or item.id
  }

  ngOnDestroy(): void {
    if (this.currentPairsSub) {
      this.currentPairsSub.unsubscribe();
    }

    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }

    if (this.pendingOrdersSub) {
      this.pendingOrdersSub.unsubscribe();
    }

    this.langSub.unsubscribe();
    this.userAuthSub.unsubscribe();
  }
}

