import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/_httpservices/API/language.service';

@Component({
  selector: 'app-pg',
  templateUrl: './pg.component.html',
  styleUrls: ['./pg.component.css']
})
export class PgComponent implements OnInit {
  sub: Subscription
  type: string = '';
  asset: string = '';
  constructor(public route: ActivatedRoute, public languageService: LanguageService, private translate: TranslateService) {

    this.languageService.language.subscribe((res) => {
      translate.setDefaultLang(res.name);
      this.translate.use(res.name);
    });
    this.sub = this.route.params.subscribe((params: any) => {
      this.type = params['type'];
      this.asset = params['asset'];
    });
  }

  ngOnInit(): void {
  }

}
