import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';

@Component({
  selector: 'app-official-verification',
  templateUrl: './official-verification.component.html',
  styleUrls: ['./official-verification.component.css']
})
export class OfficialVerificationComponent implements OnInit, OnDestroy {
  tooltiphidden:any = 'false';
  private subscription!: Subscription;
  allSettingsSub: Subscription = new Subscription;
  webname: any;
  socialmedia: any;
  payload={
    SMPage:""
  };
  public innerWidth: any;
  constructor(public _httpservice: HttpserviceComponent, private translate: TranslateService, public languageService: LanguageService) {
    this.languageService.language.subscribe((res) => {
      translate.setDefaultLang(res.name);
      this.translate.use(res.name);
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if(localStorage.getItem('Verifytooltip')){
      this.tooltiphidden = localStorage.getItem('Verifytooltip');
      // console.log(this.tooltiphidden)
    }
    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        if (data.ex.name !== undefined && data.ex.name != null) {
          this.webname = data.ex.name;
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  
  modal(ID: any) {
    const element = document.createElement('button');
    // Assign different attributes to the element.
    element.setAttribute('data-toggle', 'modal');
    element.setAttribute('type', 'button');
    element.setAttribute('data-target', '#' + ID);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  hidetooltip(){
    this.tooltiphidden = 'true';
    localStorage.setItem('Verifytooltip', this.tooltiphidden);
  }
  showtooltip(){
    this.tooltiphidden = 'false';
    localStorage.setItem('Verifytooltip', this.tooltiphidden);
  }

  async verify(){
    if(this.payload.SMPage == undefined ||this.payload.SMPage == null || this.payload.SMPage == ''){
      return;
    }
    let result:any = await this._httpservice.socialverify(this.payload).catch(err => {
      this.modal('errorpop');
        this.payload.SMPage = '';
    });
    if(result == undefined || result == null) {
      return
      }
        this.modal('successpop');
        this.socialmedia = result.social_media;
        this.payload.SMPage = '';

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }
}
