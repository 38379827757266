declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class marketService {
private _baseUrl: any;
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}


_PlaceOrders(Payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('orders/new-order', Payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

CancelExchangeOrders(_clcorders: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('orders/cancel-order', _clcorders ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

GetUserOrderHistory(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('orders/order-history?'+ request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
GetUserTradeHistory(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('orders/trade-history?'+ request).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

GetListAllAddresses() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/addresses').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_GenerateAddress(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/generate-address', request, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_getdeposits(request: any, Bearer: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/deposits', request, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

FiatManualDeposit_Request(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('fiat/fiat-deposit', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

Get_Fiat_PGs(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('fiat/get-fiat-pgs?Currency=' + payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Fiat_Deposit_Requests(TopMoversModel: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('fiat/list-fiat-manual-deposit-requests', TopMoversModel, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

FiatPG_checkout(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('fiat/pg-checkout', payload ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}



_getwithdrawals(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/withdrawals', request, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Get_Withdrawal_Limits() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/withdrawal-limits').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Get_User_Withdrawal_Limits() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/user-withdrawal-limits').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Get_Deposit_Limits() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/deposit-limits').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
Get_User_Deposit_Limits(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/user-deposit-limits/'+payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
_withdrawrequest(changedata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/crypto-withdraw', changedata).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

withdrawotp(changedata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/withdrawal-otp', changedata).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
FiatManualWithdrawal_Request(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/fiat-withdraw', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
fileupload(file: any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._PostApi('upload-file', file, ).then((result: any) => {
        resolve(result);
      }, (result: any) => {
        reject(result);
      });
  });
}
DownloadImage(request: any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._getApi('files/s3-get-url/'+ request).then((result: any) => {
        resolve(result);
      }, (res: any) => {
      reject(res);
    });
  });
}

Get_Trading_Limits() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('wallet/trading-limits').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
deleteWithdraw(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('wallet/cancel-withdraw', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
deleteFiatdeposit(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('fiat/cancel-fiat-deposit', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requesDustEstimate() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('dust/estimate').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestConvertDust(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('dust/convert', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requesDustHistory(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('dust/history', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
