import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
    providedIn: "root",
})
export class P2PService {

    p2pSinglebalance = new BehaviorSubject<any>([]);
    myOfOrCount = new BehaviorSubject<any>([]);

    constructor(public _httpservice: HttpserviceComponent) { }

    getP2PCurrency() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi("p2p/currencies").then(
                (res: any) => {
                    resolve(res);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getpaymentmethod() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi("p2p/payment-methods").then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    addUserPaymentMethod(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi("p2p/add-userpayment-method", payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getUserpaymentMethods() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi("p2p/get-userpayment-methods").then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    deleteUserPaymentMethod(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/delete-userpayment-method/' + payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    setUserNickname(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/user-name-update', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getEstimateOfferPrice(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/estimate-offer-price', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getP2PBalance(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/balance', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    async requestP2PBalance(currency: string) {
        let currCurrency = {
            Currency: currency
        };
        let result = await this.getP2PBalance(currCurrency).catch(err => { });
        if (result == undefined || result == null) {
            return
        }
            this.p2pSinglebalance.next(result[0]);

    }

    requestPostOffer(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/post-offer', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestEditOffer(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/edit-offer', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getMyOffers(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/my-offers', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getOffers(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/offers', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    updateOfferStatus(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/update-offer-status', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    cancelOffer(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/cancel-offer/' + payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getBalances() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi('p2p/balances').then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    getFees() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi('p2p/fee').then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    addOrder(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/add-order', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestOtp(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi(`p2p/request-otp`, payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestMyOrders(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/my-orders', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestOrderMakePayment(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/order-make-payment', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestCancelOrder(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/cancel-order', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestOrderAcceptPayment(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/order-accept-payment', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestAddAppeal(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/add-appeal', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestCancelAppeal(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/cancel-appeal', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestMessgages(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi(`p2p/messages/${payload}`).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestAddMessage(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/add-message', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestGetImage(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi(`files/s3-get-url/${payload}`).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestAddRating(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/add-rating', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestCounts() {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi(`p2p/open-offersorders-count`, true).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    async getCounts() {
        let result = await this.requestCounts().catch(err => {

        });
        if (result == undefined || result == null) {
            return
        }
            this.myOfOrCount.next(result);
    }

    requestP2PUserProfile(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._getApi(`p2p/user-profile/${payload}`).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

    requestBecomeMerchant(payload: any) {
        return new Promise<any>((resolve, reject) => {
            this._httpservice._PostApi('p2p/user-merchant-application', payload).then(
                (result: any) => {
                    resolve(result);
                },
                (err: any) => {
                    reject(err);
                }
            );
        });
    }

}
