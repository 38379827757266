import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()

export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const authStatus = this.authService.isAuthorized();
    if (!authStatus) {
      localStorage.removeItem('Authorization');
      this.authService.userSubject.next(null);
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}

