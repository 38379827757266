// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ppnnt{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  }
  .pnt{
    text-align: center;
    padding: 2rem 1.5rem;
    background: var(--rx-bg-default);
    border-radius: 3px;
    border: 1px solid var(--rx-border-color);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .kry{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  }
 .left-kry-child{
     text-align: left;
     padding: 10px;
 }
  .svg{
      padding: 15px;
      width: 200px;
  }
  .logo{
      text-align: center;
      text-align: left;
  }
  .logo img{
        width: 280px;
  }
  h2{
      font-size: 1.5em;
  }
  h1 {
      font-size: 5.5em;
      margin-bottom: 0.2em;
  }

  p {
        padding-bottom: 3px;
  }

  a {
      text-decoration: none;
  }

  a:hover {
    color: var(-danger);
  }


.type {
  display:inline-block;
  font-size:20px;
  line-height:1;
  text-transform:uppercase;
  font-weight:bold;
  letter-spacing:0.1em;
  color:var(--gray);
  margin-bottom:15px
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/maintenance/maintenance.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb;EACA;IACE,kBAAkB;IAClB,oBAAoB;IACpB,gCAAgC;IAChC,kBAAkB;IAClB,wCAAwC;IACxC,sEAAsE;EACxE;EACA;MACI,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,eAAe;EACnB;CACD;KACI,gBAAgB;KAChB,aAAa;CACjB;EACC;MACI,aAAa;MACb,YAAY;EAChB;EACA;MACI,kBAAkB;MAClB,gBAAgB;EACpB;EACA;QACM,YAAY;EAClB;EACA;MACI,gBAAgB;EACpB;EACA;MACI,gBAAgB;MAChB,oBAAoB;EACxB;;EAEA;QACM,mBAAmB;EACzB;;EAEA;MACI,qBAAqB;EACzB;;EAEA;IACE,mBAAmB;EACrB;;;AAGF;EACE,oBAAoB;EACpB,cAAc;EACd,aAAa;EACb,wBAAwB;EACxB,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;EACjB;AACF","sourcesContent":[".ppnnt{\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  }\n  .pnt{\n    text-align: center;\n    padding: 2rem 1.5rem;\n    background: var(--rx-bg-default);\n    border-radius: 3px;\n    border: 1px solid var(--rx-border-color);\n    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);\n  }\n  .kry{\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      flex-wrap: wrap;\n  }\n .left-kry-child{\n     text-align: left;\n     padding: 10px;\n }\n  .svg{\n      padding: 15px;\n      width: 200px;\n  }\n  .logo{\n      text-align: center;\n      text-align: left;\n  }\n  .logo img{\n        width: 280px;\n  }\n  h2{\n      font-size: 1.5em;\n  }\n  h1 {\n      font-size: 5.5em;\n      margin-bottom: 0.2em;\n  }\n\n  p {\n        padding-bottom: 3px;\n  }\n\n  a {\n      text-decoration: none;\n  }\n\n  a:hover {\n    color: var(-danger);\n  }\n\n\n.type {\n  display:inline-block;\n  font-size:20px;\n  line-height:1;\n  text-transform:uppercase;\n  font-weight:bold;\n  letter-spacing:0.1em;\n  color:var(--gray);\n  margin-bottom:15px\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
