import { PipeTransform, Pipe, NgModule } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})


export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], keyword: any, properties: string[]): any[] {
    if (!items) return [];
    if (!keyword) return items;
    debugger;
    return items.filter(item => {
      var itemFound: Boolean = false;
      for (let i = 0; i < properties.length; i++) {
        if (item[properties[i]].toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
          itemFound = true;
          break;
        }
      }
      return itemFound;
    });

  }
}

@NgModule({
  imports: [],
  declarations: [SearchFilterPipe],
  exports: [SearchFilterPipe]
})
export class SearchFilterPipeModule { }
