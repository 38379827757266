import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeViewChartRoutingModule } from './trade-view-chart-routing.module';
import { TradeViewChartComponent } from './trade-view-chart.component';



@NgModule({
  declarations: [TradeViewChartComponent],
  imports: [
    CommonModule,
    TradeViewChartRoutingModule
  ],
  exports: [TradeViewChartComponent]
})
export class TradeViewChartModule { }
