import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from '../../../_httpservices/API/httpservice.component';
import { PagerService } from '../../../_httpservices/pager.service';
import { AuthService } from 'src/app/guards/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { marketService } from 'src/app/_httpservices/API/market.service';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-all-open-orders',
  templateUrl: './all-open-orders.component.html',
  styleUrls: ['./all-open-orders.component.css'],
})
export class AllOpenOrdersComponent implements OnInit {
  subscription: Subscription = new Subscription();
  OpenOrders: any[] = [];
  Cancelthisorder: any = {};
  _resbj: any;
  _clcresult: any;
  gettabledata: any;
  // pager object
  // pager: any = {};
  // paged items
  // pagedItems: any;
  tableSort: any = {};
  pair: any;
  lang: any;
  langSub: Subscription;
  // router: any;
  public innerWidth: any;
  loadingOpenOrders: boolean = false;

  constructor(
    public _httpservice: HttpserviceComponent,
    private route: ActivatedRoute,
    public market_service: marketService,
    private pagerService: PagerService,
    public auth: AuthService,
    public _router: Router,
    public websocketservice: WebsocketService,
    public zone: NgZone,
    public languageService: LanguageService,
    public rxToastrService: RxToastrService,
    private translate: TranslateService
  ) {
    this.subscription = this.route.queryParams.subscribe((params) => {
      if (params['pair'] != null && params['pair'] !== undefined) {
        this.pair = params['pair'];
        this.OpenOrders = [];
      }
    });

    this.langSub = this.languageService.language.subscribe((res: any) => {
      this.lang = res;
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    // this.websocketservice.authOrderSubcribe(this.pair.replace('-', '_'));
    if (
      localStorage.getItem('Authorization') == null ||
      localStorage.getItem('Authorization') === undefined
    ) {
      // this._router.navigate(['/exchange']);
      return;
    }

    this.subscription = this.websocketservice.PendingOrders.subscribe(
      (data: any) => {
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((element: any) => {
            //  console.log('dataelement', element);
            let bcurr = element[2].split('_');
            //  console.log(bcurr)
            element.base = bcurr[0];
            element.quote = bcurr[1];

            if (this.OpenOrders.length === 0) {
              // console.log('iflenght0');
              this.loadingOpenOrders = true;

              if (element[10] === true) {
                const temp = {
                  base: element.base,
                  order_id: element[0],
                  pending: element[5],
                  quote: element.quote,
                  rate: element[3],
                  side: element[7],
                  status: element[10],
                  stop_price: element[8],
                  timestamp: element[9],
                  type: element[6],
                  user_id: element[1],
                  volume: element[4],
                  stop_activation: element[12],
                  trail_amount: element[14],
                  trail_percent: element[15]
                };
                this.OpenOrders.unshift(temp);
                // console.log('2', this.OpenOrders);
              }
            } else {
              //  console.log('iflenght!0');
              let isFound = false;

              this.OpenOrders.forEach((newEl: any, index) => {
                //  console.log('3', this.OpenOrders);
                if (element[10] === true) {
                  if (newEl.order_id == element[0]) {
                    isFound = true;
                    newEl.base = element.base;
                    newEl.order_id = element[0];
                    newEl.pending = element[5];
                    newEl.quote = element.quote;
                    newEl.rate = element[3];
                    newEl.side = element[7];
                    newEl.status = element[10];
                    newEl.stop_price = element[8];
                    newEl.timestamp = element[9];
                    newEl.type = element[6];
                    newEl.user_id = element[1];
                    newEl.volume = element[4];
                    newEl.stop_activation = element[12];
                    newEl.trail_amount = element[14];
                    newEl.trail_percent = element[15];
                  }
                } else {
                  if (newEl.order_id == element[0]) {
                    this.OpenOrders.splice(index, 1);
                  }
                }
              });

              if (!isFound) {
                if (element[10] === true) {
                  const temp = {
                    base: element.base,
                    order_id: element[0],
                    pending: element[5],
                    quote: element.quote,
                    rate: element[3],
                    side: element[7],
                    status: element[10],
                    stop_price: element[8],
                    timestamp: element[9],
                    type: element[6],
                    user_id: element[1],
                    volume: element[4],
                    stop_activation: element[12],
                    trail_amount: element[14],
                    trail_percent: element[15]
                  };
                  this.OpenOrders.unshift(temp);
                  //  console.log('4', this.OpenOrders);
                }
              }
            }
          });

          this.OpenOrders.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
          // this.setPage(this.pager.currentPage);
          this.loadingOpenOrders = false;
        } else {
          this.OpenOrders = [];
          this.loadingOpenOrders = false;
        }
      }
    );
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  async CancelOrders(selectedItem: any) {
    //  console.log(selectedItem);
    this.Cancelthisorder.side = selectedItem['side'];
    this.Cancelthisorder.order_id = selectedItem['order_id'];
    this.Cancelthisorder.pair =
      selectedItem['base'] + '_' + selectedItem['quote'];
    // this.Cancelthisorder.timestamp = Math.floor(new Date().getTime() / 1000);
    // this.Cancelthisorder.recvWindow = 10000;
    let results = await this.market_service
      .CancelExchangeOrders(this.Cancelthisorder)
      .catch((err) => { });
    if (results == undefined || results == null) {
      return;
    }
    this._clcresult = results;
    this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
  }

  // setPage(page: number) {
  //   // get pager object from service
  //   // console.log('as',this.OpenOrders);
  //   this.pager = this.pagerService.getPager(this.OpenOrders.length, page);

  //   // get current page of items
  //   this.OpenOrders.sort(function (a: any, b: any) {
  //     return new Date(b.OrderPlacementDate).getTime() - new Date(a.OrderPlacementDate).getTime();
  //   });

  //   this.pagedItems = this.OpenOrders.slice(this.pager.startIndex, this.pager.endIndex + 1);

  // }

  trackByFnOpen(index: any, item: any) {
    return item.order_id; // or item.id
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.langSub.unsubscribe();
  }
}
