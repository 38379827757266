import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class uiservice {
   issidenavvisible = false;
   constructor() {

   }
   togglesidenavbarstatus(): boolean {
      this.issidenavvisible = !this.issidenavvisible;
      return this.issidenavvisible;
   }
   getsidenavbarstatus(): boolean {
      return this.issidenavvisible;
   }

}
