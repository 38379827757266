import { BrowserModule, Meta } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpserviceComponent } from "./_httpservices/API/httpservice.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { RoundPipe } from "./pipes/round.pipe";
import { PagerService } from "./_httpservices/pager.service";
import { AuthService } from "./guards/auth.service";
import { CookieService } from "ngx-cookie-service";
import { ContentLayoutComponent } from "./layout/content-layout/content-layout.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SearchFilterPipeModule } from "./pipes/search-filter.pipe";
import { CurrconverterPipeModule } from "./pipes/currconverter.pipe";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CurrencyFormaterPipeModule } from "./pipes/currency-formater.pipe";
import { ExchangeComponent } from "./modules/exchange/exchange/exchange.component";
import { OrdersBookComponent } from "./modules/exchange/orders-book/orders-book.component";
import { AllOpenOrdersComponent } from "./modules/exchange/all-open-orders/all-open-orders.component";
import { OrdersFormComponent } from "./modules/exchange/orders-form/orders-form.component";
import { SmallChartComponent } from "./modules/exchange/small-chart/small-chart.component";
import { TradeFeeComponent } from "./modules/exchange/trade-fee/trade-fee.component";
import { OrderByPipeModule } from "./pipes/orderby.pipe";
import { OrdersFormModule } from "./modules/exchange/orders-form/orders-form.module";
import { MaintenanceComponent } from "./modules/maintenance/maintenance.component";
import { WebsocketService } from "./_httpservices/websocket.service";
import { authenticationService } from "./_httpservices/API/authentication.service";
import { settingService } from "./_httpservices/API/setting.service";
import { marketService } from "./_httpservices/API/market.service";
import { utilityTokenService } from "./_httpservices/API/utilitytoken.service";
import { bankaddressService } from "./_httpservices/API/bankaddress.service";
import { buyCryptoService } from "./_httpservices/API/buycrypto.service";
import { EvpService } from "./_httpservices/API/evp.service";
import { OfficialVerificationComponent } from "./modules/official-verification/official-verification.component";
import { P2PService } from "./_httpservices/API/p2p.service";
import { NumericDirectiveModule } from "./directives/numeric.directive";
import { OrderbookService } from "./_httpservices/ws-services/orderbook.service";
import { HighchartsChartModule } from 'highcharts-angular';
import { otcService } from "./_httpservices/API/otc.service";
import { launchpadService } from "./_httpservices/API/launchpad.service";
import { subaccountService } from "./_httpservices/API/subaccount.service";
import { AdditionalInformationComponent } from './modules/additional-information/additional-information.component';
import { addinfoService } from "./_httpservices/API/addinfo.service";
import { AcceptSubaccountComponent } from './modules/accept-subaccount/accept-subaccount.component';
import { DecimalPipe } from "@angular/common";
import { PersonalVerificationComponent } from "./modules/personal-verification/personal-verification.component";
import { InfoModalComponent } from './pages/info-modal/info-modal.component';
import { ShortNumberPipeModule } from "./pipes/short-number.pipe";
import { MarketsListComponent, orderByMarketPipe } from "./components/markets-list/markets-list.component";
import { TopMoversComponent } from "./components/top-movers/top-movers.component";
import { MarketsComponent } from "./modules/markets/markets.component";
import { LoadingInterceptor } from "./interceptors/loading.interceptor";
import { LinearComponent } from './pages/linear/linear.component';
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { LanguageService } from "./_httpservices/API/language.service";
import { PgComponent } from './modules/pg/pg.component';
import { BusinessKycComponent } from './modules/business-kyc/business-kyc.component';
import { tradingbotService } from "./_httpservices/API/tradingbots.service";
import { Ng2SearchPipeModule } from "./pipes/ng2-search-filter.pipe";
import { TooltipModule } from "./ng2-tooltip/tooltip.module";
import { ModalModule } from './shared/modal/modal.module';
import { TradeViewChartModule } from "./pages/trade-view-chart/trade-view-chart.module";
import { FuturesService } from "./futures-services/futures.service";
import { ConvertSmallBalanceComponent } from './modules/convert-small-balance/convert-small-balance.component';
import { ColumnSortPipeModule } from './pipes/column-sort.pipe';
import { OrdersHistoryComponent } from './modules/exchange/orders-history/orders-history.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { MarketFilterPipeModule } from "./pipes/market-filter.pipe";
import { FromNowPipeModule } from "./pipes/from-now.pipe";
import { MarkdownModule } from "ngx-markdown";
import { KycRequiredModalModule } from "./components/kyc-required-modal/kyc-required-modal.module";
import { AnnouncementsComponent } from './modules/announcements/announcements.component';
import { GridTokenComponent } from "./_httpservices/API/gridtoken.component";
import { NumberInputDirectiveModule } from "./directives/number-input.directive";
import { RxToastrComponent } from './shared/rx-toastr/rx-toastr.component';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimeFormatPipeModule } from "./pipes/time-format.pipe";

export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    RoundPipe,
    ContentLayoutComponent,
    HeaderComponent,
    ExchangeComponent,
    OrdersBookComponent,
    AllOpenOrdersComponent,
    SmallChartComponent,
    TradeFeeComponent,
    MaintenanceComponent,
    OfficialVerificationComponent,
    AdditionalInformationComponent,
    AcceptSubaccountComponent,
    PersonalVerificationComponent,
    InfoModalComponent,
    MarketsListComponent,
    TopMoversComponent,
    MarketsComponent,
    LinearComponent,
    PgComponent,
    BusinessKycComponent,
    orderByMarketPipe,
    ConvertSmallBalanceComponent,
    OrdersHistoryComponent,
    AnnouncementsComponent,
    RxToastrComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    OrdersFormModule,
    OrderByPipeModule,
    NumericDirectiveModule,
    TradeViewChartModule,
    HighchartsChartModule,
    KycRequiredModalModule,
    TimeFormatPipeModule,
    BsDatepickerModule,
    ModalModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton: true,
      preventDuplicates: true,
      extendedTimeOut: 5000,
      maxOpened: 0,
    }),
    TooltipModule,
    SearchFilterPipeModule,
    Ng2SearchPipeModule,
    CurrconverterPipeModule,
    CurrencyFormaterPipeModule,
    ColumnSortPipeModule,
    ShortNumberPipeModule,
    MarketFilterPipeModule,
    NumberInputDirectiveModule,
    FromNowPipeModule,
    MarkdownModule.forRoot(),
    NgSelectModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    HttpserviceComponent,
    GridTokenComponent,
    PagerService,
    AuthService,
    WebsocketService,
    authenticationService,
    settingService,
    marketService,
    utilityTokenService,
    bankaddressService,
    otcService,
    buyCryptoService,
    EvpService,
    P2PService,
    OrderbookService,
    launchpadService,
    subaccountService,
    tradingbotService,
    addinfoService,
    DecimalPipe,
    Meta,
    LanguageService,
    FuturesService,
    interceptorProviders
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
