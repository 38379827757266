import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import * as _ from 'lodash';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
@Component({
  selector: 'app-trade-fee',
  templateUrl: './trade-fee.component.html',
  styleUrls: ['./trade-fee.component.css'],
  providers: [DatePipe]
})
export class TradeFeeComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription;
  TradeHistory: any = [];
  tradePair: string = '';
  lang: any;
  pricePrecisionSub: Subscription = new Subscription;
  currentPrecision: any;
  pipePrecision: string = '1.0-8';
  langSub: Subscription;
  currentMarketPairSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public router: Router,
    public websocketservice: WebsocketService,
    public zone: NgZone,
    public _httpservice: HttpserviceComponent,
    public languageService: LanguageService
  ) {
    this.subscription = this.route.queryParams.subscribe(params => {
      if (params['pair'] != null && params['pair'] !== undefined) {
        // console.log('params', params['pair']);
        this.TradeHistory = [];
      }
    });

    this.langSub = this.languageService.language.subscribe((res:any) => {
      this.lang = res;
    });

    this.currentMarketPairSub = this._httpservice.currentMarketPair.subscribe((res: any) => {
      this.tradePair = `${res.currency}_${res.market}`;
    })

    this.pricePrecisionSub = this._httpservice.pricePrecision.subscribe((precision: any) => {
      if (!_.isEmpty(precision)) {
        this.currentPrecision = precision;
        this.pipePrecision = `1.0-${precision.pricePrecision}`
      }
    });
  }

  ngOnInit() {
    this.TradeHistory = [];
    this.subscription = this.websocketservice.AllMatchedData.subscribe((data: any) => {
      this.zone.runOutsideAngular(() => {
        if (!_.isEmpty(data)) {
          if (_.isEmpty(this.TradeHistory)) {
            data.forEach((element: any) => {
              element[4] = new Date(element[4]);
              this.TradeHistory.push(element);
            });
          } else {
            let tempData = data.reverse();
            tempData.forEach((element: any) => {
              element[4] = new Date(element[4]);
              this.TradeHistory.unshift(element);
            });
          }
          this.TradeHistory = this.TradeHistory.slice(0, 100);
        } else {
          this.TradeHistory = [];
        }

      });
    });
  }

  ngOnDestroy(): void {
    this.TradeHistory = [];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.pricePrecisionSub) {
      this.pricePrecisionSub.unsubscribe();
    }

    this.langSub.unsubscribe();
    this.currentMarketPairSub.unsubscribe();
  }
}
