declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class utilityTokenService {
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
}



_GetDiscountTiers() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('ut/tiers').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

_GetTokenDiscountEnrollmentStatus() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('ut/status').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}



_SetExchangeTokenDiscountEnrollment() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('ut/enroll').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}


_Dis_Enroll_ExchangeTokenDiscount() {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('ut/disenroll','').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
