import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpserviceComponent } from './API/httpservice.component';

export const ExchangeResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const service = inject(HttpserviceComponent);
  const routerService = inject(Router);
  if (!route.paramMap.get('pair')) {
    return service.getSpotConfigId().pipe(
      catchError(() => {
        // Handle error if product with the specified ID is not found
        return of(null); // Return fallback product if product with specified ID is not found
      }),
      map((product: any) => {
        // If route ID is null, re-route URL with the product ID
  
        if (!route.paramMap.get('pair')) {
          let symbol = product.default_symbol.split('_')[0]+ '-'+ product.default_symbol.split('_')[1];
          routerService.navigate(['/exchange', symbol]);
        }
        return product; // Return product data as observable
      })
    );
  }
};