import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const TOAST_STATE = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger'
};

@Injectable({
  providedIn: 'root'
})
export class RxToastrService {
  public showsToast: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toastTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Default title');
  public toastMessage: BehaviorSubject<string> = new BehaviorSubject<string>('Default toast message');
  public toastMessageHTML: BehaviorSubject<string> = new BehaviorSubject<string>('<p>Default toast message</p>');
  public toastState: BehaviorSubject<string> = new BehaviorSubject<string>(TOAST_STATE.success);
  public previousToastrMsg: string = '';
  public msgListArray: any[] = [];

  constructor() {
    this.showsToast.subscribe((status:boolean) => {
      if (!status && this.msgListArray.length > 0) {
        this.msgListArray.shift();
        if (this.msgListArray.length > 0) {
          this.toastTitle.next(this.msgListArray[0].toastTitle);
          this.toastMessage.next(this.msgListArray[0].toastMessage);
          this.toastMessageHTML.next(this.msgListArray[0].toastMessageHTML);
          this.toastState.next(this.msgListArray[0].toastState);
          this.showsToast.next(true);
        }
      }
    })
  }


  showRxToastr(toastTitle: string, toastMessage: string, toastMessageHTML: string, toastState: string): void {

    let duplicateFound = this.msgListArray.find((el: any) => toastMessage == el.toastMessage);
    if (!duplicateFound) {
      this.msgListArray.push({
        toastTitle: toastTitle,
        toastMessage: toastMessage,
        toastMessageHTML: toastMessageHTML,
        toastState: toastState
      });

      if (this.showsToast.value == false) {
        this.toastTitle.next(toastTitle);
        this.toastMessage.next(toastMessage);
        this.toastMessageHTML.next(toastMessageHTML);
        this.toastState.next(toastState);
        this.showsToast.next(true);
      }
    }

  }

  dismissToast(): void {
    this.showsToast.next(false);
  }
}
