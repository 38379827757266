declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: 'root'
})

export class authenticationService {
private _baseUrl: any;
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}

//-----------------Login---------------------//

_signin(logindata: any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._PostApi('Customer/sign-in', logindata).then((res: any) => {
      resolve(res);
    }, (err: any) => {
      reject(err);
    });
  });
}

//-----------------Signup---------------------//

_signup(request: any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._PostApi('Customer/sign-up', request).then((res: any) => {
        resolve(res);
      }, err =>{
        reject(err);
      })
  });
}

//-----------------verify---------------------//

_accountverification(OTP: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/verify-account', OTP).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

//-----------------OTP'S---------------------//

_OtpNum(logdata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/token', logdata).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });

}

//-----------------ResendOTP---------------------//

_ResendOtpmail() {
  const payload = {
    destination: "email",
    AuthToken:localStorage.getItem('tempAuthToken'),
    client:"",
    os:"",
    browser:""
  }
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/signin-resend-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}


_ResendOtpsms() {
  const payload = {
    destination: "sms",
    AuthToken:localStorage.getItem('tempAuthToken'),
    client:"",
    os:"",
    browser:""
  }
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/signin-resend-otp', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

//-----------------Forgot Pass---------------------//

_sentlink_Fpassword(emaildetail: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/forgot-password', emaildetail, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

//-----------------Reset Pass---------------------//

_ResetpasswordRequest(changedata: any,) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/reset-password', changedata, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

//-----------------Change Pass---------------------//

_Changepass(changedata: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/change-password', changedata, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

//-----------------Req Change Pass OTP---------------------//

_Getotprequest(request: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('Customer/request-change-password-OTP', request, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

RequestCaptcha(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._getApi('captcha?' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
      reject(res);
    });
  });
}

KycInfo(payload:any) {
  return new Promise<any>((resolve, reject) => {
    this._httpservice._getApi('kyc/info/'+payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
      reject(res);
    });
  });
}

requestGoBack() {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/sign-back').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
