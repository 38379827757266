import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class AuthService {
  public userSubject: BehaviorSubject<any | null>;
  public userAuth: Observable<any | null>;

  constructor(private router: Router) {
    this.userSubject = new BehaviorSubject<any | null>(null);
    this.userAuth = this.userSubject.asObservable();

    if (_.isNull(this.userSubject.value)) {
      this.userSubject.next(localStorage.getItem('Authorization')!)
    }
  
  }

  cachedRequests: Array<HttpRequest<any>> = [];
  public collectFailedRequest(request:any): void {
    this.cachedRequests.push(request);
  }

  isAuthorized() {
    return this.getUserAuth !== null;
  }

  public get getUserAuth() {
    return this.userSubject.value;
  }

  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('Authorization');
    this.userSubject.next(null);
  }
  

}
