declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class otcService {
private _baseUrl: any;
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}



RequestRFQ(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('otc/rfq', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
convertRFQ(payload: any, ) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('otc/convert', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

GetrfqTrades() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('otc/trades').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

Getareachart(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('otc/area-charts?'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestSparklines() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('market/sparklines/ALL/4').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
