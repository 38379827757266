import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { HttpserviceComponent } from "../../_httpservices/API/httpservice.component";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";

@Component({
  selector: "app-markets",
  templateUrl: "./markets.component.html",
  styleUrls: ["./markets.component.css"],
})
export class MarketsComponent implements OnInit, OnDestroy {
  public innerWidth: any;
  constructor(
    public websocketservice: WebsocketService,
    private router: Router,
    public _httpservice: HttpserviceComponent,
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.websocketservice.tickerunSubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
  }
}
