declare function loadGoogleV3captcha(key: string): any;
import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from './_httpservices/websocket.service';
import { DOCUMENT, DecimalPipe } from '@angular/common';
// import { HttpserviceComponent } from './_httpservices/httpservice.component';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute
} from '@angular/router'
import { HttpserviceComponent } from './_httpservices/API/httpservice.component';
import * as _ from 'lodash';
import { LanguageService } from './_httpservices/API/language.service';
import { registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/zh';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeHi from '@angular/common/locales/hi';
import localeKo from '@angular/common/locales/ko';
import localeEs from '@angular/common/locales/es';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';
import localeBg from '@angular/common/locales/bg';
import localeDa from '@angular/common/locales/da';
import localeRu from '@angular/common/locales/ru';
import localeEl from '@angular/common/locales/el';
import localeFi from '@angular/common/locales/fi';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeNn from '@angular/common/locales/nn';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeSr from '@angular/common/locales/sr';
import localeRo from '@angular/common/locales/ro';
import { AuthService } from './guards/auth.service';
import { MarketService } from './_httpservices/ws-services/market.service';
import { StoreService } from './store/store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  // Sets initial value to true to show loading spinner on first load
  loading = true
  posts: any;
  _baseUrl: any;
  connection: any;
  data: any;
  SellOrderList: any;
  BuyOrderList: any;
  TotalRecordsBuy: any;
  toFixedSpecial_Socket: any;
  DataTickerHub: any;
  pushDataAllSell: any;
  pushDataAllBuy: any;
  pushDataAllMatched: any;
  arraydata: any;
  jsonlist: any;
  datalength: any;
  TotalRecordsSell: any;
  orders: any;
  matchorders: any;
  buypendingorders: any;
  sellpendingorders: any;
  lodash = _;
  stylesheetsList = ['default', '859b7156', '398f11ed', '99a4519c', '351k85on', 'c35a5xb4', '8w6dreg5'];
  settings: any;
  lang: any;
  IsLogin: boolean = false;
  public innerWidth: any;
  mobileAppPanel: boolean = false
  linkType: string = "google";
  arrayTradeSub!: Subscription;
  langSub: Subscription;
  allSettingsSub!: Subscription;
  routerSub!: Subscription;
  description: any;
  userProfileSub: Subscription = new Subscription();

  constructor(
    public toastr: ToastrService,
    public websocketservice: WebsocketService,
    private router: Router,
    public _httpservice: HttpserviceComponent,
    private languageService: LanguageService,
    private metaService: Meta,
    public authService: AuthService,
    private titleService: Title,
    public MarketService: MarketService,
    public storeService: StoreService,
    public _decimalPipe: DecimalPipe,
    @Inject(DOCUMENT) private document: Document
  ) {
      this._httpservice.GetAllSettings();
      this._httpservice.requestAnnouncements();
    if (this.authService.isAuthorized()) {
      this._httpservice.requestRenewToken();
      // this._httpservice._GetuserProfile();
    }

    if (_.isNull(localStorage.getItem('Language')) || _.isEmpty(localStorage.getItem('Language'))) {
      const langObj = {
        name: 'English',
        code: 'EN'
      }
      localStorage.setItem('Language', JSON.stringify(langObj));
      this.languageService.language.next(langObj);
    } else if (!_.isNull(localStorage.getItem('Language')) && localStorage.getItem('Language') !== undefined && !_.isEmpty(localStorage.getItem('Language'))) {
      let currentLang: any = JSON.parse(localStorage.getItem('Language')!);
      this.languageService.enum_details.forEach((element: any) => {
        if (element.code.toLowerCase() == currentLang?.code.toLowerCase()) {
          localStorage.setItem('Language', JSON.stringify(element));
          this.languageService.language.next(element);
        }
      });
    }

    this.langSub = this.languageService.language.subscribe((res) => {
      this.lang = res;

      switch (res.code) {
        case 'ZH':
          registerLocaleData(localeZh, res.code.toLowerCase());
          break;

        case 'EN':
          registerLocaleData(localeEn, res.code.toLowerCase());
          break;

        case 'FR':
          registerLocaleData(localeFr, res.code.toLowerCase());
          break;

        case 'Hi':
          registerLocaleData(localeHi, res.code.toLowerCase());
          break;

        case 'Ko':
          registerLocaleData(localeKo, res.code.toLowerCase());
          break;

        case 'ES':
          registerLocaleData(localeEs, res.code.toLowerCase());
          break;

        case 'CS':
          registerLocaleData(localeCs, res.code.toLowerCase());
          break;

        case 'DE':
          registerLocaleData(localeDe, res.code.toLowerCase());
          break;

        case 'PT':
          registerLocaleData(localePt, res.code.toLowerCase());
          break;

        case 'BG':
          registerLocaleData(localeBg, res.code.toLowerCase());
          break

        case 'DA':
          registerLocaleData(localeDa, res.code.toLowerCase());
          break

        case 'RU':
          registerLocaleData(localeRu, res.code.toLowerCase());
          break

        case 'EL':
          registerLocaleData(localeEl, res.code.toLowerCase());
          break

        case 'FI':
          registerLocaleData(localeFi, res.code.toLowerCase());
          break

        case 'HU':
          registerLocaleData(localeHu, res.code.toLowerCase());
          break

        case 'IT':
          registerLocaleData(localeIt, res.code.toLowerCase());
          break

        case 'NN':
          registerLocaleData(localeNn, res.code.toLowerCase());
          break

        case 'PL':
          registerLocaleData(localePl, res.code.toLowerCase());
          break

        case 'SV':
          registerLocaleData(localeSv, res.code.toLowerCase());
          break

        case 'TR':
          registerLocaleData(localeTr, res.code.toLowerCase());
          break

        case 'SR':
          registerLocaleData(localeSr, res.code.toLowerCase());
          break

        case 'RO':
          registerLocaleData(localeRo, res.code.toLowerCase());
          break

        default:
          registerLocaleData(localeEn, res.code.toLowerCase());
          break;
      }
    });


    this._httpservice.loggedIn.subscribe((login: any) => {
      this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
        if (_.isEmpty(data)) return;
    
        this.settings = data;
    
        const isPhoneVerificationEnabled = _.get(data, 'phone_verification', false);
        const isPhoneVerificationMandatory = _.get(data, 'phone_verification_mandatory', false);
    
        const handleUserProfile = (data2: any) => {
          if (_.isEmpty(data2)) return;
    
          const mobileStatus = _.get(data2, 'mobileNumberVerificationStatus');
    
          if (mobileStatus === "Not Verified") {
            this.router.navigate(['/phone-verification']);
          } else if (mobileStatus === "Verified" && this.authService.isAuthorized()) {
            this._httpservice.getRiskRules(false);
          }
        };
    
        if (isPhoneVerificationEnabled) {
          if (isPhoneVerificationMandatory) {
            this.userProfileSub = this._httpservice.userProfile.subscribe(handleUserProfile);
          } else if (this.authService.isAuthorized()) {
            this._httpservice.getRiskRules(false);
          }
        } else if (this.authService.isAuthorized()) {
          this._httpservice.getRiskRules(false);
        }
      });
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 992) {
      this.mobileAppPanel = true;
    } else {
      this.mobileAppPanel = false;
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 992) {
      this.mobileAppPanel = true;
    } else {
      this.mobileAppPanel = false;
    }

    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.description = data.seo.content;
        this.metaService.updateTag({name:'keywords', content: data.seo.keywords});
        this.metaService.updateTag({ property: 'og:type', content: 'website' });
        this.metaService.updateTag({ property: 'og:title', content: 'Signup page' });
        this.metaService.updateTag({ property: 'og:description', content: 'The official way to invite your friends.' });
        this.metaService.updateTag({ property: 'og:url', content: `${data.homepage_url!}/ref` });
        this.metaService.updateTag({ property: 'og:image', content: data.ex.dark_logo_url! });

        this.settings = data;
        if (_.isNull(localStorage.getItem('stylesheet')) || localStorage.getItem('stylesheet') == "") {
          let queryString = `${data.css_name}.css`
          localStorage.setItem('stylesheet', data.css_name);
          this.loadStyle(queryString);
        } else {
          if (localStorage.getItem('stylesheet')?.trim() !== data.css_name) {
            let queryString = `${data.css_name}.css`
            localStorage.setItem('stylesheet', data.css_name);
            this.loadStyle(queryString);
          }
        }

        if (data.captcha_mode == 'GoogleV3') {
          loadGoogleV3captcha(data.site_key);
        }
      }
    });

    this.routerSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url.split('/')[1] == 'exchange') {
          this.arrayTradeSub = this._httpservice.arrayTrade.subscribe((data: any) => {
            if (!_.isEqual(data, '')) {
              if (this.router.url.split('/')[1] == 'exchange') {
                let pair = this.router.url.split('/')[2];
                let symbol = pair.split('-');
                let base = symbol[0];
                let quote = symbol[1];
                this.metaService.updateTag({name:'description',content:`${this.description}`});
                let link = document.querySelector<HTMLAnchorElement>('link[rel*=\'icon\']') || document.querySelector<HTMLAnchorElement>('link');
                document.getElementsByTagName('head')[0].appendChild(link!);
                let newTitle = `${data !== undefined ? this._decimalPipe.transform(data, "1.0-8") : ''} | ${pair} | ${this.settings?.ex?.name !== undefined ? this.settings?.ex?.name : ''}`;
                this.titleService.setTitle(newTitle);
              } else {
                let link = document.querySelector<HTMLAnchorElement>('link[rel*=\'icon\']') || document.querySelector<HTMLAnchorElement>('link');
                document.getElementsByTagName('head')[0].appendChild(link!);
                let newTitle = `${this.settings?.ex?.name !== undefined ? this.settings?.ex?.name : ''}`;
                this.titleService.setTitle(newTitle);
              }

            }
          });
        } else {
          if (this.arrayTradeSub) {
            this.arrayTradeSub.unsubscribe();
          }
          let link = document.querySelector<HTMLAnchorElement>('link[rel*=\'icon\']') || document.querySelector<HTMLAnchorElement>('link');
          document.getElementsByTagName('head')[0].appendChild(link!);
          let newTitle = `${this.settings?.ex?.name !== undefined ? this.settings?.ex?.name : ''}`;
          this.titleService.setTitle(newTitle);
        }
      }
    });

    if (!_.isNull(localStorage.getItem('stylesheet')) && localStorage.getItem('stylesheet') !== "") {
      let isStyleSheetExist = this.stylesheetsList.find(element => element == localStorage.getItem('stylesheet')?.trim());
      if (isStyleSheetExist) {
        let queryString = `${localStorage.getItem('stylesheet')?.trim()}.css`
        this.loadStyle(queryString);
      } else {
        let queryString = `${this.stylesheetsList[0]}.css`
        localStorage.setItem('stylesheet', this.stylesheetsList[0]);
        this.loadStyle(queryString);
      }

    }
  }

  activateLink(type: string) {
    this.linkType = type
  }

  loadStyle(styleName: string) {
    this._httpservice.getThemeName(styleName);
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.arrayTradeSub.unsubscribe();
    this.allSettingsSub.unsubscribe();
    this.routerSub.unsubscribe();
    this.langSub.unsubscribe();
  }

}
