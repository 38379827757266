import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addinfoService } from 'src/app/_httpservices/API/addinfo.service';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.css']
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {

  showError: boolean = false;
  private unsubscriber: Subject<void> = new Subject<void>();

  SecurityProcessing = false;
  SecureDetails: any;
  errmsgs: any;
  formfields: any;
  answerPayload =[]
  langCode: any;
  addInfoSub: Subscription = new Subscription();
  lang: any;
  langSelected: any;
  LanguageDropDown: boolean = false;

  constructor(
    public _httpservice: HttpserviceComponent,
    public addinfoservice: addinfoService,
    public router: Router,
    public rxToastrService: RxToastrService,
    private translate: TranslateService,
    public languageService: LanguageService,
  ) {

    this.languageService.language.subscribe((res) => {
      this.lang = res;
      translate.setDefaultLang(res.name);
      this.langSelected = this.lang.name
      this.translate.use(this.langSelected);
      this.langCode = res.code;
    });
  }

  ngOnInit(): void {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
    .pipe(takeUntil(this.unsubscriber))
    .subscribe((_) => {
      history.pushState(null, '');
      this.showError = true;
    });

    this._httpservice.getRiskRules();
    this.addInfoSub = this._httpservice.addonifo.subscribe((data: any) => {
      this.formfields = data;
    });
  }

  switchLanguage(data: any) {
    let langgggg = typeof data === 'object';
    if (langgggg == false) {
      this.languageService.enum_details.forEach((element: any) => {
        if (element.name == data) {
          localStorage.setItem('Language', JSON.stringify(element));
          this.lang = JSON.parse(localStorage.getItem('Language')!);
          this.langSelected = this.lang.name
          this.translate.use(this.langSelected);
          this.languageService.language.next(this.lang);
          this.LanguageDropDown = false;
        }

      });
    } else {
      localStorage.setItem('Language', JSON.stringify(data));
      this.lang = JSON.parse(localStorage.getItem('Language')!);
      this.langSelected = this.lang.name
      this.translate.use(this.langSelected);
      this.languageService.language.next(this.lang);
      this.LanguageDropDown = false;
    }
    this._httpservice.getRiskRules();
  }

   submitAns(){
    let obj:any = [];
    let found = false;
    this.formfields.forEach((element:any) => {
      let ansobj={
        Question:element.Question,
        Answer: element.value
      }
      if (element.value == undefined || element.value == null || element.value == '') {
        this.rxToastrService.showRxToastr('', this.translate.instant('Setting.kyc.text'), '', 'danger');
        found = true;
        return false;
      } else{
        obj.push(ansobj);
        found = false;
      }
    });
    if (!found) {
      this.finalanswer(obj);
    }

    // console.log(obj)

  }

  async finalanswer(obj: any){
    let results:any = await this.addinfoservice.fieldsAns(obj, this.langCode).catch(err => {

    });
    if(results == undefined || results == null) {
      return
      }
      this.router.navigate(['/profile-verification']);
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.addInfoSub) {
      this.addInfoSub.unsubscribe();
    }
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
