declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class buyCryptoService {

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
}

GetCurrencyBuycrypto() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('buycrypto/currencies').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}


GetBuycryptoOffer(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('buycrypto/rates/' + payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

BuyCryptoOrder(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('buycrypto/create-order', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

GetBuycryptoOrders() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('buycrypto/my-orders').then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

BuyCryptoPaymentMethods(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('buycrypto/payment-methods', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

}
