declare var apidocUrl: any;
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  NgZone
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import {
  Subscription
} from 'rxjs';
import {
  HttpserviceComponent
} from '../../_httpservices/API/httpservice.component';
import 'rxjs/add/observable/interval';
import {
  uiservice
} from 'service/ui-service';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import * as _ from 'lodash';
import { P2PService } from 'src/app/_httpservices/API/p2p.service';
import { AuthService } from 'src/app/guards/auth.service';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { authenticationService } from 'src/app/_httpservices/API/authentication.service';
import { ModalService } from 'src/app/shared/modal';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  _resultbj: any = {};
  status2 = false;
  static status: any;
  MarketData: any = [];
  togglesidenavbarstatus = true;
  @ViewChild('Market_button', { static: false }) myDiv?: ElementRef;
  Topmovers: any;
  MarketName_ = '';
  DefaultMarketName = '';
  MarketNameList: any;
  DefaultMarket: any;
  allMarketData: any = [];
  MKTName: any = [0];
  SelectedMarketData: any;
  showmarketpopup = false;
  ShortMarketList = false;
  CurrentMarket = '';
  CurrentCurrency = '';
  CurrentMarketData: any;
  CurrencyWalletBalance: any = {
    balance: 0
  };
  SelectedCurrency = '';
  SelectedMarket = '';
  MarketWalletBalance: any = {
    balance: 0
  };
  urlbal = '';
  private subscription: Subscription = new Subscription;
  // private alive = false;
  lodash = _;
  CurrentURL: any;
  log_URL: any;
  dark_log_URL: any;
  favIcon_Url: any;
  FavPairs: any[] = [];
  favpair = {
    data: [],
  };
  fav = false;
  homepage = false
  siteMode: any = 'Day';
  siteModeIcon: any = 'Night';

  OpenDropDown = false;
  LanguageDropDown = false;
  CurrencyDropDown = false;
  USDPRICELIST = {};
  cookieValue: any;

  fiat_List: any;
  FiatEstimation: any;
  langSelected: any;
  CurrencySelected: any;
  supportURL = "";



  CurrentConvertCurr = 'USD';
  FiatUSDPrice: any;
  CryptoUSDPrice: any;

  loading: any;
  CurrentUSDRate: any;
  CurrentRateinUSD: any;
  CurrentBTCRate: any;
  CurrentSelectedCurrencyprice: any;
  notificationalert: any;
  show = false;
  Disable_Discounts: any;
  webname: any;

  SGMarketdata: any;
  loginStatus = false;
  interval?: NodeJS.Timeout;
  Disable_login: any;
  Disable_Affiliate: any;
  Enable_Simplex: any;
  Enable_Dust: any;
  P2P_Trade: any;
  Enable_EVP: any;
  Enable_Buycrypto: any;
  featuresDrop = false;
  sideNavbar = false;
  myOfOrCountSub: Subscription = new Subscription;
  myOrdersCount = 0;
  myOffersCount = 0;
  public innerWidth: any;
  collapseMenu: string = '';
  launchpadEnable: boolean = false;
  convertEnable: boolean = false;
  settingdata: any;
  favMarketData: any[] = [];
  marketSub: Subscription = new Subscription;
  userSubjSub: Subscription = new Subscription;
  subaccountModule: any
  marketPair = {
    currency: '',
    market: ''
  }
  lang: any;
  accType: any;
  profile: any;
  showCookieAlert: boolean = false;
  announcementData: any[] = [];
  notificationData: any[] = [];
  note_count: number = 0;
  announce_count: number = 0;
  notifyOpened: boolean = false;
  notifyCurrent: any;
  allSettingsSub: Subscription = new Subscription;
  giftCardsStatus: boolean = false;
  apiDocURL: any;
  profileAutoBuying: boolean = false;
  configAutoBuying: boolean = false;
  support: boolean = false;
  profileGridTrading: boolean = false;
  configGridTrading: boolean = false;

  constructor(
    private routes: ActivatedRoute,
    private router: Router,
    public _httpservice: HttpserviceComponent,
    public _uiservice: uiservice,
    private translate: TranslateService,
    public websocketservice: WebsocketService,
    public zone: NgZone,
    private p2pService: P2PService,
    private authService: AuthService,
    public languageService: LanguageService,
    public _authservice: authenticationService,
    public modalService: ModalService,
    public rxToastrService: RxToastrService
  ) {
    this.languageService.language.subscribe((res) => {
      this.lang = res;
      this.langSelected = this.lang.name
      this.translate.use(this.langSelected);
    });

  }

  trackByFnMarket(index: any, Xmarketdata: any) {
    return Xmarketdata.base;
  }

  ngOnInit() {
    this.apiDocURL = apidocUrl;
    this.innerWidth = window.innerWidth;
    let browser = this.getBrowserName();
    if (browser == 'ie' || browser == 'edge') {
      this.modal('browsecomp');
    }

    const siteMode = localStorage.getItem('siteMode');
    if (siteMode === undefined || siteMode == null || siteMode === 'null' || siteMode.trim() === '') {
      this.siteMode = 'Night';
      localStorage.setItem('siteMode', this.siteMode);
      this.setDefaultMode(this.siteMode);
    } else if (siteMode !== '' && siteMode != null && siteMode !== undefined) {
      this.setDefaultMode(siteMode);
    }

    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        this.settingdata = data;
        if (data.ex.name !== undefined && data.ex.name != null) {
          this.webname = data.ex.name;
        }
        if (data.ex.logo_url !== undefined && data.ex.logo_url != null) {
          this.log_URL = data.ex.logo_url;
        }
        if (data.ex.dark_logo_url !== undefined && data.ex.dark_logo_url != null) {
          this.dark_log_URL = data.ex.dark_logo_url;
        }
        if (data.token_discount_module !== undefined && data.token_discount_module != null) {
          this.Disable_Discounts = data.token_discount_module;
        }

        if (data.notification !== undefined && data.notification != null && data.notification != '') {
          this.notificationalert = data.notification;
          this.show = true;
        }
        if (data.disable_login !== undefined && data.disable_login != null) {
          this.Disable_login = data.disable_login;
        }
        if (data.referral_module !== undefined && data.referral_module != null) {
          this.Disable_Affiliate = data.referral_module;
        }
        if (data.support_url !== undefined && data.support_url != null) {
          this.supportURL = data.support_url;
        }

        if (data.support !== undefined && data.support != null) {
          this.support = data.support;
        }
        

        // if (data.enable_Simplex !== undefined || data.enable_Simplex != null) {
        //   this.Enable_Simplex = data.enable_Simplex.toLowerCase();
        // }
        // if (data.enable_DustConversion !== undefined || data.enable_DustConversion != null) {
        //   this.Enable_Dust = data.enable_DustConversion.toLowerCase();
        // }
        if (data.evp_module !== undefined && data.evp_module != null) {
          this.Enable_EVP = data.evp_module;
        }
        if (data.buy_crypto_module !== undefined && data.buy_crypto_module != null) {
          this.Enable_Buycrypto = data.buy_crypto_module;
        }

        // if (data.navBarLogo_Url !== undefined || data.navBarLogo_Url != null) {
        //   this.navBarLogo_Url = data.navBarLogo_Url;
        // }
        if (data.p2p_module !== undefined && data.p2p_module != null) {
          this.P2P_Trade = data.p2p_module;
        }

        if (data.launchpad_module !== undefined && data.launchpad_module != null) {
          this.launchpadEnable = data.launchpad_module;
        }

        if (data.subaccount_module !== undefined && data.subaccount_module != null) {
          this.subaccountModule = data.subaccount_module;
        }

        if (data.otc_module !== undefined && data.otc_module != null) {
          this.convertEnable = data.otc_module;
        }
        if (data.homepage_url == undefined || data.homepage_url == null || data.homepage_url.length < 5) {
          this.homepage = false;
        } else {
          this.homepage = true;
        }

        if (data.gift_card_module !== undefined && data.gift_card_module != null) {
          this.giftCardsStatus = data.gift_card_module;
        }

        if (data.grid_trading !== undefined && data.grid_trading != null) {
          this.configGridTrading = data.grid_trading;
        }

        if (data.autobuy !== undefined && data.autobuy != null && !_.isEmpty(data.autobuy)) {
          this.configAutoBuying = data.autobuy.module;
        }

        this.fiat_List = data.fiat_list.split(',');
        if (localStorage.getItem('CurrentFiatCurrency') == null || localStorage.getItem('CurrentFiatCurrency') == '') {
          if (this.fiat_List.length != 0) {
            if (this.fiat_List.includes('USD') == true) {
              this.FiatEstimation = 'USD';
            } else {
              this.FiatEstimation = this.fiat_List[0];
            }
          }
          localStorage.setItem('CurrentFiatCurrency', this.FiatEstimation.trim());
          this._httpservice._SelectedFiatCurrency(this.FiatEstimation);
        } else {
          this.FiatEstimation = localStorage.getItem('CurrentFiatCurrency');
          this._httpservice._SelectedFiatCurrency(localStorage.getItem('CurrentFiatCurrency'));
        }

        if (localStorage.getItem('Authorization') !== null) {
          HeaderComponent.status = 'login';
        } else {
          HeaderComponent.status = 'logout';
        }
      }
    });

    this.subscription = this._httpservice.userProfile.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        this.profile = data;
        if (data.accType !== undefined && data.accType != null) {
          this.accType = data.accType;
        }
        if (data.grid_trading !== undefined && data.grid_trading != null) {
          this.profileGridTrading = data.grid_trading;
        }
        if (data.auto_buying !== undefined && data.auto_buying != null) {
          this.profileAutoBuying = data.auto_buying;
        }
      }
    });

    this.subscription = this._httpservice.CurrentFiatCurr.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.cookieValue = data;
      }
      this.CurrentConvertCurr = localStorage.getItem('CurrentFiatCurrency')!;
      // console.log('Current fiat Currency', this.cookieValue);
    });

    this.subscription = this.websocketservice.CryptoPrice.subscribe((data: any) => {
      // console.log(data);
      this.CryptoUSDPrice = data;
      // console.log('Get CryptoCurrency', this.CryptoUSDPrice);
      if (data !== undefined && data != null && data.length !== 0) {
        data.forEach((element: any) => {
          if (element[1] > 0) {
            // @ts-ignore: Unreachable code error
            this.USDPRICELIST[element[0]] = element[1];
          }
        });
      }
      // @ts-ignore: Unreachable code error
      this.CrrBTCRate = 1 / this.USDPRICELIST['BTC'];
    });

    this.subscription = this.websocketservice.FiatPrice.subscribe((data: any) => {
      this.FiatUSDPrice = data.rate_list;
      // console.log('Get FiatCurrency', this.FiatUSDPrice);
      if (data !== undefined && data != null && data.length !== 0) {
        data.rate_list.forEach((element: any) => {
          if (element.rate > 0 && element.currency != 'BTC') {
            // @ts-ignore: Unreachable code error
            this.USDPRICELIST[element.currency] = 1 / element.rate;
          }
        });
      }
    });

    // this.websocketservice.RefreshBalance();
    this.subscription = this.websocketservice.CurrWalletBalance.subscribe((bal: any) => {
      this.CurrencyWalletBalance.balance = bal.Currency;
      this.MarketWalletBalance.balance = bal.Market;
    });

    this._httpservice.currentMarketPair.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.marketPair.currency = data.currency;
        this.marketPair.market = data.market;
      }
    });

    this.routes.params.subscribe((params) => {
      if (this.router.url.split('/')[1] == 'exchange') {
        this.CurrentURL = this.router.url.split('/')[1];
        this.websocketservice.CurrURLName(this.CurrentURL);

        this.showmarketpopup = true;
        this.ShortMarketList = false;
        this.status2 = false;
      } else {
        this.showmarketpopup = false;
      }
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.split('/')[1] == 'exchange') {
          this.CurrentURL = this.router.url.split('/')[1];
          this.websocketservice.CurrURLName(this.CurrentURL);

          this.showmarketpopup = true;
          this.ShortMarketList = false;
          this.status2 = false;
        } else {
          this.showmarketpopup = false;
        }
      }
    });

    this.userSubjSub = this.authService.userSubject.subscribe((data: any) => {
      this.loginStatus = !_.isNull(data) && !_.isEqual(data, "") ? true : false;
      if (_.isEqual(this.loginStatus, true) && _.isEqual(this.P2P_Trade, true)) {
        this.p2pService.getCounts();
        clearInterval(this.interval!)
        this.interval = setInterval(() => {
          this.p2pService.getCounts();
        }, 30000);
      }


      if (_.isEqual(this.loginStatus, false)) {
        clearInterval(this.interval!)
      }
    });

    this.myOfOrCountSub = this.p2pService.myOfOrCount.subscribe((res) => {
      if (_.isEmpty(res)) {
        return;
      }
      this.myOrdersCount = res.Open_Orders;
      this.myOffersCount = res.Open_Offers;
    });

    const cookieMode = localStorage.getItem('cookieMode')!;
    if (cookieMode == null || cookieMode == undefined || cookieMode == '' || cookieMode == 'false') {
      this.showCookieAlert = true;
    }

    this.getAnnouncements();
  }

  confirmCokies() {
    this.showCookieAlert = false;
    localStorage.setItem('cookieMode', 'true')
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  ShowShortMarket(item: any) {
    this.DefaultMarket = item;
    this.status2 = !this.status2;
    if (this.status2 === true) {
      this.ShortMarketList = true;
    } else if (this.status2 === false) {
      this.ShortMarketList = false;
    }
  }

  togglenavbarstatus(): boolean {
    return this._uiservice.togglesidenavbarstatus();
  }

  modal(ID: any) {
    const element = document.createElement('button');
    // Assign different attributes to the element.
    element.setAttribute('data-toggle', 'modal');
    element.setAttribute('type', 'button');
    element.setAttribute('data-target', '#' + ID);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  setDefaultMode(data: any) {
    if (data == 'Day') {
      document.documentElement.setAttribute('data-theme', 'light');
      this.siteMode = 'Day';
      this._httpservice.themeColorMode(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    } else {
      this.siteMode = 'Night';
      document.documentElement.setAttribute('data-theme', 'dark');
      this._httpservice.themeColorMode(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    }
  }

  toggleMode() {
    if (!_.isEqual(localStorage.getItem('siteMode'), '')) {
      if (localStorage.getItem('siteMode') == 'Day') {
        this.siteMode = 'Night';
        document.documentElement.setAttribute('data-theme', 'dark');
        this._httpservice.themeColorMode(this.siteMode);
        localStorage.setItem('siteMode', this.siteMode);
      } else {
        document.documentElement.setAttribute('data-theme', 'light');
        this.siteMode = 'Day';
        this._httpservice.themeColorMode(this.siteMode);
        localStorage.setItem('siteMode', this.siteMode);
      }
    }
  }

  ChooseLanguage() {
    this.LanguageDropDown = !this.LanguageDropDown;
  }

  ChooseCurrency() {
    this.CurrencyDropDown = !this.CurrencyDropDown;
  }

  switchLanguage(data: any) {
    let langgggg = typeof data === 'object';
    if (langgggg == false) {
      this.languageService.enum_details.forEach((element: any) => {
        if (element.name == data) {
          localStorage.setItem('Language', JSON.stringify(element));
          this.lang = JSON.parse(localStorage.getItem('Language')!);
          this.langSelected = this.lang.name
          this.translate.use(this.langSelected);
          this.languageService.language.next(this.lang);
          this.LanguageDropDown = false;
        }

      });
    } else {
      localStorage.setItem('Language', JSON.stringify(data));
      this.lang = JSON.parse(localStorage.getItem('Language')!);
      this.langSelected = this.lang.name
      this.translate.use(this.langSelected);
      this.languageService.language.next(this.lang);
      this.LanguageDropDown = false;
    }
  }

  SelectCurr() {
    localStorage.setItem('CurrentFiatCurrency', this.FiatEstimation.trim());
    this._httpservice._SelectedFiatCurrency(this.FiatEstimation);
  }

  toggleFeatures() {
    this.featuresDrop = !this.featuresDrop;
  }

  docpage() {
    window.open(apidocUrl, '_blank');
  }

  currencyConverter(rate: any, currencyName: any) {
    let USD_in_one_convertcurr = 1;
    // @ts-ignore: Unreachable code error
    let USD_Rate = this.USDPRICELIST[currencyName];
    if (USD_Rate == undefined) {
      USD_Rate = 0;
    }
    if (this.cookieValue != 'USD') {
      // @ts-ignore: Unreachable code error
      let USD_ConvertCurr_Rate = this.USDPRICELIST[this.cookieValue];
      if (USD_ConvertCurr_Rate == undefined) {
        USD_in_one_convertcurr = 0;
      } else {
        USD_in_one_convertcurr = 1 / USD_ConvertCurr_Rate;
      }
    }
    return rate * (USD_Rate) * USD_in_one_convertcurr;
  }

  redirectToHome() {
    window.open(this.settingdata.homepage_url, '_blank');
  }

  getnavbarstatus(): boolean {
    return this._uiservice.getsidenavbarstatus();
  }

  goToExchange() {
    this.router.navigate(['markets']);
  }

  checkloginstatus() {
    this._uiservice.togglesidenavbarstatus();
    if (localStorage.getItem('Authorization') == null || localStorage.getItem('Authorization') == undefined) {
      this.router.navigate(['/login']);
      HeaderComponent.status = 'logout';
    }
  }

  openSideNav() {
    this.sideNavbar = true;
    this.openCollapseMenu('default');
  }

  closeSideNav() {
    this.sideNavbar = false;
    this.openCollapseMenu('default');
  }

  async logBack() {
    let result: any = await this._authservice.requestGoBack().catch((err: any) => {
    });
    if (result == undefined || result == null) {
      return
    }
    localStorage.setItem('Authorization', result.access_token);
    this.authService.userSubject.next(result.access_token);
    this._httpservice.startRefreshTokenTimer();
    this._httpservice._GetuserProfile();
    this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
    window.location.href = "/sub-accounts/lists"
  }

  logout() {
    this.modal('logoutModal');
  }

  proceedLogout() {
    this.handleCloseModal('logoutModal');
    this._uiservice.togglesidenavbarstatus();
    localStorage.removeItem('Authorization');
    localStorage.removeItem('MobileVerify');
    this.authService.userSubject.next(null);
    this.router.navigate(['/markets']);
    this.websocketservice.logOutUnsubscribe();
    this.websocketservice.logOutaccess();
    this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
    this._httpservice.requestAnnouncements();
    this.closeSideNav();
  }

  handleCloseModal(ID: any) {
    document.getElementById(ID)?.classList.remove("show", "d-block");
    document.getElementById(ID)!.style.display = 'none';
    document.getElementById(ID)!.style.paddingRight = '';
    document.body!.style.paddingRight = '';
    document.body.classList.remove("modal-open");
    document.querySelectorAll(".modal-backdrop")
      .forEach(el => {
        el.remove();
      });
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.status2 = false;
    this.ShortMarketList = false;
    this.featuresDrop = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 992) {
      this.sideNavbar = false;
    }
  }

  openCollapseMenu(data: string) {
    if (this.collapseMenu == data) {
      this.collapseMenu = '';
      return;
    }
    this.collapseMenu = data;

  }

  goToSupport() {
    window.open(this.supportURL, "_blank");
  }

  async getAnnouncements() {
    this._httpservice.announceNotify.subscribe((result: any) => {
      if (!_.isEmpty(result)) {
        let announcement = result.filter((element: any) => {
          return element.Type == 'A';
        });
        let notification = result.filter((element: any) => {
          return element.Type == 'N';
        });
        this.announcementData = announcement.slice(0,5);
        this.notificationData = notification.slice(0,5);

        let tempNotify:any[] = []
        this.notificationData.forEach(element => {
          if(element.IsRead == false){
            tempNotify.push(element);
          }
        });
        this.note_count = tempNotify.length;

        let countAnnounce: any[] = JSON.parse(localStorage.getItem('Announcements')!);
        if(_.isEmpty(countAnnounce)){
          this.announce_count = this.announcementData.length;
          return;
        }
        let tempArray = []
        this.announcementData.forEach(element => {
          countAnnounce.forEach((el:any) => {
            if(el == element.Id){
              tempArray.push(element)
              // this.announce_count = element;
            }
          });
        });
        this.announce_count = this.announcementData.length - tempArray.length;
      } else {
        this.announcementData = [];
        this.notificationData = [];
      }
    });
  }
  async readNotification(ID:any) {
    let result: any = await this._httpservice.requestReadNotification(ID).catch((err: any) => {
    });
    if (result == undefined || result == null) {
      return
    }
    this.getAnnouncements();
  }
  showAnnouncement(data: any) {
    let countAnnounce: any[] = JSON.parse(localStorage.getItem('Announcements')!);
    if(_.isEmpty(countAnnounce)){
      countAnnounce = [];
    countAnnounce.push(data.Id);
    localStorage.setItem('Announcements', JSON.stringify(countAnnounce));
    } else{
      const found =  !!countAnnounce.find((element:any) => element == data.Id);
       if(!found){
        countAnnounce.push(data.Id);
        localStorage.setItem('Announcements', JSON.stringify(countAnnounce));
       }
    };
    let tempArray = []
    this.announcementData.forEach(element => {
      countAnnounce.forEach((el:any) => {
        if(el == element.Id){
          tempArray.push(element)
          // this.announce_count = element;
        }
      });
    });
    this.announce_count = this.announcementData.length - tempArray.length;

    if (!_.isEmpty(this.notifyCurrent)) {
      if (this.notifyCurrent.Id == data.Id) {
        if (this.notifyOpened) {
          this.announcementData.forEach((element: any) => {
            element.opend = false;
          });
          this.notificationData.forEach((element: any) => {
            element.opend = false;
          });
          this.notifyOpened = false;
        } else {
          this.handlechangeOpen(data);
        }
      } else {
        this.handlechangeOpen(data);
      }
    } else {
      this.handlechangeOpen(data);
    }
  }

  handlechangeOpen(data: any) {
    this.notifyOpened = true;
    this.notifyCurrent = data;
    this.announcementData.forEach((element: any) => {
      element.opend = false;
      if (data.Id == element.Id) {
        element.opend = true;
      }
    });
    this.notificationData.forEach((element: any) => {
      element.opend = false;
      if (data.Id == element.Id) {
        element.opend = true;
        if(element.Type == 'N') {
          this.readNotification(data.Id);
        }
      }
    });
  }


  handleTickets() {
    this.router.navigate(['/tickets']);
  }


  goToAnnouncements() {
    this.router.navigate(['/announcements']);
    this.closeAnnouncementModal();
    this.closeNotifyModal();
  }

  openAnnouncementModal() {
    this.openModal('AnnouncementModal');
  }

  closeAnnouncementModal() {
    this.closeModal('AnnouncementModal');
  }

  openNotifyModal() {
    this.openModal('NotificationModal');
  }

  closeNotifyModal() {
    this.closeModal('NotificationModal');
  }

  openSettingsWeb() {
    this.openModal('setttingsweb');
  }

  closeSettingsWeb() {
    this.closeModal('setttingsweb');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  ngOnDestroy() {
    // this.alive = false;
    this.subscription.unsubscribe();
    if (this.myOfOrCountSub) {
      this.myOfOrCountSub.unsubscribe();
    }
    if (this.marketSub) {
      this.marketSub.unsubscribe();
    }

    if (this.userSubjSub) {
      this.userSubjSub.unsubscribe();
    }

    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }

}
