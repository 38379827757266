declare var baseUrl: any;
import {
  OnInit,
  Injectable
} from '@angular/core';
import 'rxjs/add/operator/map';
import {
  ToastrService
} from 'ngx-toastr';
import {
  AuthService
} from '../../guards/auth.service';
// import * as crypto from 'crypto-js';
import {
  HttpClient,
  HttpContext,
  HttpHeaders
} from '@angular/common/http';
import * as _ from 'lodash';
import { IS_SKIP_LOADER } from '../loader.token';
import { LanguageService } from './language.service';

@Injectable()
export class GridTokenComponent implements OnInit {
  _baseUrl: string;


  constructor(private http: HttpClient, public toastr: ToastrService, public authService: AuthService,public languageService: LanguageService,) {
    this._baseUrl = baseUrl;


  }


  ngOnInit() {
  }



  _getApi(route: string = "", skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = localStorage.getItem('Grid_Token')!;
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.get<any>(this._baseUrl + route, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          let res = data.hasOwnProperty('body') ? data.body : {};
          if(res.hasOwnProperty('status')) {
            res.status2 = res.status;
          }
          res.status = data.status;
          resolve(res);

        },
        error: err => {
          let error = err.hasOwnProperty('error') ? err.error : {};
          error.status = err.status;
          reject(error);
        }
      });
    });
  }

  _PostApi(route: string, payload: any = null, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = localStorage.getItem('Grid_Token')!;
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.post<any>(this._baseUrl + route, payload, { observe: 'response', headers: header, context: contexts })
        .subscribe({
          next: data => {
            let res = data.hasOwnProperty('body') ? data.body : {};
            res.status = data.status;
            resolve(res);
          },
          error: err => {
            let error = err.hasOwnProperty('error') ? err.error : {};
            error.status = err.status;
            reject(error);
          }
        });
    });
  }

  _PutApi(route: any, file: any, fileType: any, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders({
        'Content-Type': 'image/' + fileType,
        'Accept': '*/*',
        'Origin': 'https://v2.tradify.live',
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.put<any>(route, file, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          resolve(data);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

}






