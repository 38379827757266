import { Injectable, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class OrderbookService {
  Sellshown: any = [];
  Buyshown: any = [];
  sellBookData = new BehaviorSubject<any>([]);
  buyBookData = new BehaviorSubject<any>([]);

  constructor(public websocketservice: WebsocketService, public zone: NgZone) {
    this.websocketservice.allOpenOrders.subscribe(
      (data: any) => {
        this.zone.runOutsideAngular(() => {
          if (!_.isEmpty(data)) {
            if (data.hasOwnProperty("snapshot")) {
              if (!_.isEmpty(data.snapshot)) {
                if (data.snapshot[1]) {
                  let Sellmap = new Map();
                  data.snapshot[1].forEach((element: any) => {
                    Sellmap.set(element[0], element[1])
                  });
                  this.Sellshown = Sellmap;
                }
                if (data.snapshot[0]) {
                  let Buymap = new Map();
                  data.snapshot[0].forEach((element: any) => {
                    Buymap.set(element[0], element[1])
                  });
                  this.Buyshown = Buymap;
                }
              }
            } else if (data.hasOwnProperty("updates")) {
              if (!_.isEmpty(data.updates[1])) {
                data.updates[1].forEach((el: any) => {
                  // Delete
                  // if (!_.isEmpty(this.Sellshown)) {

                  // }
                  // Update or insert
                  // if (this.Sellshown.has(el[0])) {
                  //   this.Sellshown.set(el[0], el[1])
                  // } else
                  if (el[1] > 0) {
                    this.Sellshown.set(el[0], el[1])
                  }
                  else {
                    if (this.Sellshown.has(el[0])) {
                      this.Sellshown.delete(el[0]);
                    }
                  }
                });
              }
              if (!_.isEmpty(data.updates[0])) {
                data.updates[0].forEach((el: any) => {
                  // Delete
                  // if (!_.isEmpty(this.Buyshown)) {
                  // if (this.Buyshown.has(el[0]) && el[1] <= 0) {
                  //   this.Buyshown.delete(el[0]);
                  // }
                  // }
                  // Update or Insert
                  //  if (this.Buyshown.has(el[0])) {
                  //     this.Buyshown.set(el[0], el[1])
                  //   } else
                  if (el[1] > 0) {
                    this.Buyshown.set(el[0], el[1])
                  }
                  else {
                    if (this.Buyshown.has(el[0])) {
                      this.Buyshown.delete(el[0]);
                    }
                  }
                });
              }
            }

            this.sellBookData.next(this.Sellshown);
            this.buyBookData.next(this.Buyshown);
          } else {
            this.sellBookData.next([]);
            this.buyBookData.next([]);
          }
        });
      }
    );
  }
}
