import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';

@Component({
  selector: 'app-accept-subaccount',
  templateUrl: './accept-subaccount.component.html',
  styleUrls: ['./accept-subaccount.component.css']
})
export class AcceptSubaccountComponent implements OnInit {
  Id: any;
  info: any
  lang: any;

  constructor(public _httpservice: HttpserviceComponent, private route: ActivatedRoute, private router: Router, private translate: TranslateService, public languageService: LanguageService) {
      this.languageService.language.subscribe((res) => {
        translate.setDefaultLang(res.name);
        this.translate.use(res.name);
        this.lang = res;
      });
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.Id = params['id'];
   });
   this.getinfo();
  }

 async getinfo(){
    let result = await this._httpservice.Get_subInfo(this.Id).catch(err => {

    });
    if(result == undefined || result == null) {
      return
      }
      this.info = result;
  }

  async acceptSA(){
    let result = await this._httpservice.Accept_subInfo(this.Id).catch(err => {

    });
    if(result == undefined || result == null) {
      return
      }
      this.router.navigate(['/sub-accounts']);
  }

  async rejectSA(){
    let result = await this._httpservice.Reject_subInfo(this.Id).catch(err => {

    });
    if(result == undefined || result == null) {
      return
      }
      this.router.navigate(['/sub-accounts']);
  }

}
