declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class launchpadService {
private _baseUrl: any;
  data: any;

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}



projectLists(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('launchpad/list', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
buy(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('launchpad/buy', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
buyHistory() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('launchpad/buying-history', ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
stats() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('launchpad/stats', ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
validatePromocode(payload: any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('launchpad/validate-promo-code', payload, ).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
}
