import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersFormComponent } from './orders-form.component';
import { BuyComponent } from './buy/buy.component';
import { SellComponent } from './sell/sell.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NumericDirectiveModule } from 'src/app/directives/numeric.directive';
import { DecimalPipe } from 'src/app/pipes/decimal.pipe';
import { TooltipModule } from 'src/app/ng2-tooltip/tooltip.module';
import { NumberInputDirectiveModule } from 'src/app/directives/number-input.directive';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TooltipModule,
    NumberInputDirectiveModule,
    NumericDirectiveModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    OrdersFormComponent,
    BuyComponent,
    SellComponent,
  ],
  exports: [
    OrdersFormComponent
  ],
  providers : [DecimalPipe]
})
export class OrdersFormModule { }

