import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";

@Component({
  selector: "app-info-modal",
  templateUrl: "./info-modal.component.html",
  styleUrls: ["./info-modal.component.css"],
})
export class InfoModalComponent implements OnInit {
  modalStatus = false;
  constructor(
    private _httpservice: HttpserviceComponent
  ) {

    this._httpservice.infoModalStatus.subscribe((res) => {
      this.modalStatus = res;
    });
  }

  ngOnInit(): void { }

  closeInfoModal() {
    this.modalStatus = false;
    this._httpservice.triggerInfoModal(false);
  }

  isJson(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
