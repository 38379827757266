// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-200{
  width: 200px;
}
.kycmenu{
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.bgclr{
  color: white;
  border-radius: 5px;
}
.pdfsize{
  width: 150px;
  cursor: pointer;
}
.boxx{
  border: 1px solid;
    border-radius: 10px;
}
.bg{
  background-color: #f6f9fc;
  border-radius: 10px;
}
.text-justify{
  text-align: justify;
}
.fs-xs{
  font-size: x-small;
}
.fs-sl{
  font-size: smaller;
}
/* .vol{
  color: #1f2c73;
} */
.flx{
flex-grow: 1;
font-size: 24px;
}
.card-title {
font-size: 18px !important;
}

.oxx-height {
  height: calc(100vh - 650px);
}
.ox-height {
  height: calc(100vh - 350px);
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/business-kyc/business-kyc.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,iBAAiB;IACf,mBAAmB;AACvB;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;;GAEG;AACH;AACA,YAAY;AACZ,eAAe;AACf;AACA;AACA,0BAA0B;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".w-200{\n  width: 200px;\n}\n.kycmenu{\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  border-radius: 4px;\n  font-weight: 400;\n  line-height: 1.5;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n}\n.bgclr{\n  color: white;\n  border-radius: 5px;\n}\n.pdfsize{\n  width: 150px;\n  cursor: pointer;\n}\n.boxx{\n  border: 1px solid;\n    border-radius: 10px;\n}\n.bg{\n  background-color: #f6f9fc;\n  border-radius: 10px;\n}\n.text-justify{\n  text-align: justify;\n}\n.fs-xs{\n  font-size: x-small;\n}\n.fs-sl{\n  font-size: smaller;\n}\n/* .vol{\n  color: #1f2c73;\n} */\n.flx{\nflex-grow: 1;\nfont-size: 24px;\n}\n.card-title {\nfont-size: 18px !important;\n}\n\n.oxx-height {\n  height: calc(100vh - 650px);\n}\n.ox-height {\n  height: calc(100vh - 350px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
