import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KycRequiredModalComponent } from './kyc-required-modal.component';
import { ModalModule } from 'src/app/shared/modal';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    KycRequiredModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [KycRequiredModalComponent]
})
export class KycRequiredModalModule { }
