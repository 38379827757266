import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { authenticationService } from 'src/app/_httpservices/API/authentication.service';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';

@Component({
  selector: 'app-personal-verification',
  templateUrl: './personal-verification.component.html',
  styleUrls: ['./personal-verification.component.css']
})
export class PersonalVerificationComponent implements OnInit {
  userprofile: any;
  lodash = _;
  kycInfo: any;
  withValue: any[] = [];
  withoutValue: any[] = [];
  bwithValue: any[] = [];
  bwithoutValue: any[] = [];
  Langcode: any;
  allSettingsSub: Subscription = new Subscription;
  settings: any;
  kybModule: boolean = false;
  public innerWidth: any;

  constructor(public _httpservice: HttpserviceComponent, public router: Router, public _authservice: authenticationService, private translate: TranslateService,
    public languageService: LanguageService) {
    this.languageService.language.subscribe((res) => {
      translate.setDefaultLang(res.name);
      this.translate.use(res.name);
      this.Langcode = res.code;
    });

    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.settings = data;
        if (data.kyb_module != null && data.kyb_module != undefined) {
          this.kybModule = data.kyb_module;
        }
      }
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getUserprofile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  goToKYC() {
    this.router.navigate(['KYC'])
  }

  goTobusiness_KYC() {
    this.router.navigate(['business-kyc'])
  }

  async getUserprofile() {
    let result = await this._httpservice._GetuserProfile().catch((err) => { });
    if (result == undefined || result == null) {
      return;
    }
    this.userprofile = result;
    this.Kyc();
  }

  async Kyc() {
    let result: any = await this._authservice.KycInfo(this.Langcode).catch((err) => { });
    if (result == undefined || result == null) {
      return;
    }
    this.kycInfo = result;
    this.withoutValue = [];
    this.withValue = [];
    this.bwithoutValue = [];
    this.bwithValue = [];
    // this.kycInfo.i.forEach((element:any) => {
    //   if(element.LabelValue == ''){
    //     this.withoutValue.push(element);
    //   }
    //   if(element.LabelValue != ''){
    //     this.withValue.push(element);
    //   }
    // });

    this.kycInfo.b.forEach((element: any) => {
      if (element.LabelValue == '') {
        this.bwithoutValue.push(element);
      }
      if (element.LabelValue != '') {
        this.bwithValue.push(element);
      }
    });
    // console.log(result)

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }

}
