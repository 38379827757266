import {
  Injectable
} from '@angular/core';
import * as _ from 'lodash';


import * as moment from 'moment';
import {
  HistoryProviderService
} from './history-provider.service';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class StreamProviderService {

  subscription!: {
    channel: string;
    uid: any;
    resolution: any;
    symbolInfo: any;
    listener: any;
    lastBar: any;
  };

  constructor(public websocketservice: WebsocketService, private historyProviderService: HistoryProviderService) {

    this.websocketservice.datacharts.subscribe((data: any) => {
      if (data[0] !== undefined && data[0] != null && data[0].length !== 0) {
        const barData = data[0];
        if (this.subscription) {
          if (barData[5] < this.subscription.lastBar.time) {
            return;
          }

          const lastBar = this.updateBar(barData, this.subscription);

          this.subscription.listener(lastBar);
          // update our own record of lastBar
          this.subscription.lastBar = lastBar;
        }
      }

    });

    // this.websocketservice.sendDataTickerMatched.subscribe((data: any) => {
    //   if (data != null && data !== undefined && data.length !== 0) {
    //     this.updateVolume(data.Data);
    //     this.updatePrice(data.Data);
    //   }
    // });
  }

  public streamProvider = {
    subscribeBars: (symbolInfo:any, resolution:any, updateCb:any, uid:any, resetCache:any) => {
      const [baseCurrency, quoteCurrency] = symbolInfo.name.split('/');
      const lastBar = _.get(
        this.historyProviderService.historyProvider,
        `history.${symbolInfo.name}.lastBar`, {
          time: moment()
            .startOf('m')
            .valueOf(),
          open: 0,
          close: 0,
          high: 0,
          low: 0,
          volume: 0,
        },
      );

      this.subscription = {
        channel: `${baseCurrency}${quoteCurrency}1`,
        uid,
        resolution,
        symbolInfo,
        listener: updateCb,
        lastBar,
      };

    },
    unsubscribeBars: (uid:any) => {
      // connection.stop();
    },
  };

  updateVolume = (ticker:any) => {
    if (!_.isEmpty(this.subscription)) {
      const lastBar = this.subscription.lastBar;
      lastBar.volume += ticker.Volume;
      this.subscription.listener(lastBar);
    }
  }

  updatePrice = (price:any) => {
    // console.log('update price starting');
    if (!_.isEmpty(this.subscription)) {
      const lastBar = this.subscription.lastBar;
      // console.log('update price condition matched', price.Rate);
      if (price.Rate !== 0) {
        //  console.log('price Rate', price.Rate);
        if (price.Rate < lastBar.low) {
          lastBar.low = price.Rate;
          // console.log('lastBar low', lastBar);
        } else if (price.Rate > lastBar.high) {
          lastBar.high = price.Rate;
          // console.log('lastBar high', lastBar);
        }

        lastBar.close = price.Rate;

        this.subscription.listener(lastBar);
      }
    }
    // console.log('update price ending');
  }

  updateBar = (barData:any, subscription:any) => {
    const lastBar = subscription.lastBar;
    let resolution = subscription.resolution;

    if (resolution.includes('D')) {
      // 1 day in minutes === 1440
      resolution = 1440;
    } else if (resolution.includes('W')) {
      // 1 week in minutes === 10080
      resolution = 10080;
    }

    const coeff = resolution * 60 * 1000;

    const isSameInterval =
      Math.floor(barData[5] / coeff) === Math.floor(lastBar[5] / coeff);
    let _lastBar;

    if (!isSameInterval) {
      // create a new candle, use last close as open **PERSONAL CHOICE**
      _lastBar = {
        open: barData[0],
        high: barData[1],
        low: barData[2],
        close: barData[3],
        volume: barData[4],
        time: barData[5],
      };
    } else {
      // update lastBar candle!
      if (barData[3] < lastBar.low) {
        lastBar.low = barData[3];
      } else if (barData[3] > lastBar.high) {
        lastBar.high = barData[3];
      }

      lastBar.volume += barData[4];
      lastBar.close = barData[3];
      _lastBar = lastBar;
    }
    return _lastBar;
  }

}
