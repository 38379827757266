declare var baseUrl2: any;
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders
} from '@angular/common/http';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../guards/auth.service';
import { LanguageService } from '../_httpservices/API/language.service';
import { IS_SKIP_LOADER } from '../_httpservices/loader.token';

@Injectable({
  providedIn: 'root'
})
export class FuturesService {
  private _baseUrl: any;

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrService, public authService: AuthService,
    public languageService: LanguageService) { 
      this._baseUrl = baseUrl2;
    }

  _getApi(route: string = "", skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = !_.isNull(this.authService.getUserAuth) && !_.isEqual(this.authService.getUserAuth, '') ? this.authService.getUserAuth : "";
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.get<any>(this._baseUrl + route, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          let res = data.hasOwnProperty('body') ? data.body : {};
          res.status = data.status;
          resolve(res);

        },
        error: err => {
          let error = err.hasOwnProperty('error') ? err.error : {};
          error.status = err.status;
          reject(error);
        }
      });
    });
  }

  _PostApi(route: string, payload: any = null, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = !_.isNull(this.authService.getUserAuth) && !_.isEqual(this.authService.getUserAuth, '') ? this.authService.getUserAuth : "";
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.post<any>(this._baseUrl + route, payload, { observe: 'response', headers: header, context: contexts })
        .subscribe({
          next: data => {
            let res = data.hasOwnProperty('body') ? data.body : {};
            res.status = data.status;
            resolve(res);
          },
          error: err => {
            let error = err.hasOwnProperty('error') ? err.error : {};
            error.status = err.status;
            reject(error);
          }
        });
    });
  }

  _PutApi(route: any, file: any, fileType: any, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders({
        'Content-Type': 'image/' + fileType,
        'Accept': '*/*',
        'Origin': 'https://v2.tradify.live',
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.put<any>(route, file, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          resolve(data);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

}
