import { Directive, ElementRef, HostListener, Input, NgModule } from '@angular/core';

@Directive({
  selector: '[numeric]'
})
export class NumericDirective {
  @Input("decimals") decimals: any = 0;
  @Input("dotAllowed") dotAllowed: boolean = false;

  // private regex: RegExp = new RegExp(/^\d+[.,]?\d{0,2}$/g);// user can put . or , char.
  // input also cannot start from , or .
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {

      if (!this.dotAllowed) {
        var regExpString =
          "^\\s*((\\d+(\\.\\d{0," +
          this.decimals +
          "})?)|((\\d*(\\.\\d{1," +
          this.decimals +
          "}))))\\s*$"

        return String(value).match(new RegExp(regExpString));
      } else {
        var regExpString = `^\\d*\\.?\\d{0,${this.decimals}}\\s*$`;
        return String(value).match(new RegExp(regExpString));
      }

    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    let next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !this.check(String(next))) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let pastedText = event.clipboardData!.getData('text');
    if (!this.check(pastedText)) {
      event.preventDefault();
    }
  }

  @HostListener("input", ["$event"])
  onInputChange(event: any) {
    const initalValue = this.el.nativeElement.value;
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    this.el.nativeElement.value = initalValue.replace(regex, '');
    if (this.decimals <= 0) {
      this.el.nativeElement.value = initalValue.replaceAll(/[^\d.]/g, '');
    } else {
      if (!this.dotAllowed) {
        this.el.nativeElement.value = initalValue.replaceAll(/[^\d.]/g, '');
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/(\d*.)(.*)/, '$1') + this.el.nativeElement.value.replace(/(\d*.)(.*)/, '$2').replace(/\./g, '');
      } else {
        this.el.nativeElement.value = initalValue.replaceAll(/[^\d.]/g, '');
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/(\d*.)(.*)/, '$1') + this.el.nativeElement.value.replace(/(\d*.)(.*)/, '$2').replace(/\./g, '');
      }
    }

    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

@NgModule({
  imports: [],
  declarations: [NumericDirective],
  exports: [NumericDirective]
})
export class NumericDirectiveModule { }