import { Component, OnInit } from '@angular/core';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-rx-toastr',
  templateUrl: './rx-toastr.component.html',
  styleUrls: ['./rx-toastr.component.css']
})
export class RxToastrComponent implements OnInit {

  toastClass!: string[];
  toastMessage!: string;
  showsToast: boolean = false;

  constructor(
    public rxToastrService: RxToastrService
  ) {
    this.rxToastrService.showsToast.subscribe((data) => {
      if (data) {
      this.showsToast = data;
      }
    })
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  closeToastr(): void {
    this.showsToast = false;
    this.rxToastrService.dismissToast();
  }

}
