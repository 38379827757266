import { Component, OnInit, OnDestroy, NgZone, HostListener, EventEmitter, Output } from '@angular/core';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { LanguageService } from 'src/app/_httpservices/API/language.service';

@Component({
  selector: 'app-orders-form',
  templateUrl: './orders-form.component.html',
  styleUrls: ['./orders-form.component.css']
})
export class OrdersFormComponent implements OnInit, OnDestroy {

  allSettingsSub: Subscription;
  balsub: Subscription;
  currentMarketPairSub: Subscription;
  settings: any;
  CurrencyWalletBalance: any = { balance: 0 };
  MarketWalletBalance: any = { balance: 0 };
  CurrentMarket: string ='';
  CurrentCurrency: string ='';
  lang: any;
  public innerWidth: any;
  @Output() closeOrderFormModal = new EventEmitter<string>();

  constructor(private translate: TranslateService, public _httpservice: HttpserviceComponent, private activatedRoute: ActivatedRoute,
    private router: Router,public zone: NgZone, public websocketservice: WebsocketService,
    public languageService: LanguageService) {
      this.languageService.language.subscribe((res) => {
        this.translate.use(res.name);
        this.lang = res;
      });

    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      this.settings = data;
    });

    this.balsub = this.websocketservice.CurrWalletBalance.subscribe((bal: any) => {
      this.CurrencyWalletBalance.balance = bal.Currency;
      this.MarketWalletBalance.balance = bal.Market;
    });

    this.currentMarketPairSub = this._httpservice.currentMarketPair.subscribe(
      (cury: any) => {
        this.CurrentMarket = cury.market;
        this.CurrentCurrency = cury.currency;
      }
    );
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  closeFormModal() {
    this.closeOrderFormModal.emit('closeOrderFormModal');
  }

  handleSide(side:string) {
    this._httpservice.orderTypeSelected.next(side);
  }

  ngOnDestroy() {
    this.balsub.unsubscribe();
    this.currentMarketPairSub.unsubscribe();
    this.allSettingsSub.unsubscribe();
  }
}
