// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1071;
    padding: 1rem;
}

.info-modal-back {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1071;
    background-color: rgb(19 22 32 / 65%);
}

.info-modal-inner {
    width: 480px;
    background-color: var(--rx-bg-secondary);
    border-radius: 0.3rem;
    color: var(--rx-text);
    padding: 1.5rem;
    z-index: 1072;
    font-size: 0.85rem;
    box-shadow: 0px 2px 10px 5px rgb(19 23 34 / 50%);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/info-modal/info-modal.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,wCAAwC;IACxC,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,gDAAgD;AACpD","sourcesContent":[".info-modal {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1071;\n    padding: 1rem;\n}\n\n.info-modal-back {\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    z-index: 1071;\n    background-color: rgb(19 22 32 / 65%);\n}\n\n.info-modal-inner {\n    width: 480px;\n    background-color: var(--rx-bg-secondary);\n    border-radius: 0.3rem;\n    color: var(--rx-text);\n    padding: 1.5rem;\n    z-index: 1072;\n    font-size: 0.85rem;\n    box-shadow: 0px 2px 10px 5px rgb(19 23 34 / 50%);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
