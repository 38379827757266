declare var baseUrl: any;
import {
  Component,
  OnInit,
  Injectable
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  BehaviorSubject,
  Subscription,
  from
} from 'rxjs';
import 'rxjs/add/operator/map';

import {
  ToastrService
} from 'ngx-toastr';

import {
  AuthService
} from '../../guards/auth.service';
// import * as crypto from 'crypto-js';
import {
  HttpClient,
  HttpContext,
  HttpHeaders
} from '@angular/common/http';
import * as _ from 'lodash';
import { IS_SKIP_LOADER } from '../loader.token';
import { LanguageService } from './language.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class HttpserviceComponent implements OnInit {
  _baseUrl: string;
  _coinSelected = '';
  // {{rest_api_url}}/api/GetSettings , call this endpoint for exception defination.
  coinname_: any;
  posts: any;
  connection: any;
  data: any;
  SellOrderList: any;
  BuyOrderList: any;
  TotalRecordsBuy: any;
  toFixedSpecial_Socket: any;
  DataTickerHub: any;
  pushDataAllSell: any;
  pushDataAllBuy: any;
  pushDataAllMatched: any;
  arraydata: any;
  jsonlist: any;
  datalength: any;
  TotalRecordsSell: any;
  orders: any;
  matchorders: any;
  buypendingorders: any;
  sellpendingorders: any;
  IsLogin = false;
  authEnabled: boolean = false;
  DepositCurrentCurrency = new BehaviorSubject<any>([]);
  WithdrawCurrentCurrency = new BehaviorSubject<any>([]);

  _walletALlBalance = new BehaviorSubject<any>([]);
  StatusSignup = new BehaviorSubject<any>([]);
  Logindata = new BehaviorSubject<any>([]);
  AllSettings = new BehaviorSubject<any>([]);
  CurrentFiatCurr = new BehaviorSubject<any>([]);
  ChartColor = new BehaviorSubject<any>([]);
  userProfile = new BehaviorSubject<any>([]);
  announceNotify = new BehaviorSubject<any>([]);
  addonifo = new BehaviorSubject<any>([]);
  pricePrecision = new BehaviorSubject<any>({});
  UTCseconds: number = 0;
  APIUTCTime: any;
  arrayTrade = new BehaviorSubject<any>('');
  TimeMismatch: boolean = false;
  myHeaders: any;
  p2pSinglebalance = new BehaviorSubject<any>([]);
  CurrencySettings = new BehaviorSubject<any>([]);
  currentMarketPair = new BehaviorSubject<any>({});
  infoModalStatus = new BehaviorSubject<any>(false);
  showKycRequiredModal = new BehaviorSubject<boolean>(false);
  public refreshTokenTimeout!: NodeJS.Timeout;
  Mobile: any;
  allbalance: any[] = [];
  favIcon: HTMLLinkElement = document.querySelector('#appIcon')!;
  currentTheme = new BehaviorSubject<string>('');
  orderTypeSelected = new BehaviorSubject<string>('buy');
  langCode: any;

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrService, public authService: AuthService,
    public languageService: LanguageService,
    private titleService: Title) {
    this._baseUrl = baseUrl;

    this.languageService.language.subscribe((res) => {
      this.langCode = res.code;
    });

    this.authService.userSubject.subscribe((data: any) => {
      this.IsLogin = !_.isNull(data) && !_.isEqual(data, "") ? true : false;
      if (this.IsLogin) {
        this._GetuserProfile();
      }

      if (_.isNull(data)) {
        clearInterval(this.refreshTokenTimeout);
      }
    });

    this.GetCurrencySettings();
  }

  SignupstatusSend(value: any) {
    this.StatusSignup.next(value);
  }

  tradeValue(value: any) {
    this.arrayTrade.next(value.toString());
  }

  loginarray(value: any) {
    this.Logindata.next(value);
  }

  ngOnInit() {
  }

  themeColorMode(data: any) {
    this.ChartColor.next(data);
  }

  _getApi(route: string = "", skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = !_.isNull(this.authService.getUserAuth) && !_.isEqual(this.authService.getUserAuth, '') ? this.authService.getUserAuth : "";
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.get<any>(this._baseUrl + route, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          let res = data.hasOwnProperty('body') ? data.body : {};
          if(res.hasOwnProperty('status')) {
            res.status2 = res.status;
          }
          res.status = data.status;
          resolve(res);

        },
        error: err => {
          let error = err.hasOwnProperty('error') ? err.error : {};
          error.status = err.status;
          reject(error);
        }
      });
    });
  }

  _PostApi(route: string, payload: any = null, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let userAuth: string = !_.isNull(this.authService.getUserAuth) && !_.isEqual(this.authService.getUserAuth, '') ? this.authService.getUserAuth : "";
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': userAuth
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.post<any>(this._baseUrl + route, payload, { observe: 'response', headers: header, context: contexts })
        .subscribe({
          next: data => {
            let res = data.hasOwnProperty('body') ? data.body : {};
            res.status = data.status;
            resolve(res);
          },
          error: err => {
            let error = err.hasOwnProperty('error') ? err.error : {};
            error.status = err.status;
            reject(error);
          }
        });
    });
  }

  _PutApi(route: any, file: any, fileType: any, skipLoader: boolean = false) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders({
        'Content-Type': 'image/' + fileType,
        'Accept': '*/*',
        'Origin': 'https://v2.tradify.live',
      })
      let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
      this.http.put<any>(route, file, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          resolve(data);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  updatebal(data: any) {

    this._walletALlBalance.next(data);
  }





  _getwalletbalance(request: any, Bearer: any) {

    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/GetBalance', request,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });

    });
  }












  // _googleauthNum(logdata1: any) {
  //   var loginotp =
  //     "grant_type=" + "password" + "&username=" + localStorage.getItem("tempAuthToken") + "&password=" + logdata1.googleauthotp;
  //   // alert(localStorage.getItem("Authorization"));
  //
  //   .append('Content-Type', 'application/json');
  //   return new Promise((resolve, reject) => {
  //     this._PostApi(this._baseUrl + 'token', loginotp, )
  //       .then((result: any) => {
  //         resolve(result);
  //       }).catch(err => console.log(err));
  //   });
  // }

  _googleauthNum(logdata1: any, skipLoader: boolean = false) {
    var loginotp =
      'grant_type=' + 'password' + '&username=' + localStorage.getItem('tempAuthToken') + '&password=' + logdata1.googleauthotp;
    let header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
    let contexts = new HttpContext().set(IS_SKIP_LOADER, skipLoader);
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this._baseUrl + 'token', loginotp, { observe: 'response', headers: header, context: contexts }).subscribe({
        next: data => {
          resolve(data);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  _GetuserProfile() {
    return new Promise((resolve, reject) => {
      if (!this.IsLogin) {
        reject({ message: 'Go to Login' });
        return;
      }
      this._getApi('Customer/profile').then((res: any) => {
        resolve(res);
        this.Mobile = res.mobileNumberVerificationStatus;
        this.userProfile.next(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  GetAllSettings() {
    return new Promise<any>((resolve, reject) => {
      this._getApi('config')
        .then((result: any) => {
          resolve(result);
          this.AllSettings.next(result);
        }, (res: any) => {
          reject(res);
          this.router.navigate(['404']);
        });
    });
  }

  getSpotConfigId() {
    return this.http.get(`${baseUrl}config`);
 }

  s3fileupload(file: any) {
    return new Promise<any>((resolve, reject) => {
      this._PostApi('files/s3-put-url/' + file).then((result: any) => {
        resolve(result);
      }, (result: any) => {
        reject(result);
      });
    });
  }

  GetCurrencySettings() {
    return new Promise((resolve, reject) => {
      this._getApi('assets', true).then((res: any) => {
        resolve(res);
        this.CurrencySettings.next(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  socialverify(payload: any) {
    return new Promise((resolve, reject) => {
      this._PostApi('social-verification', payload).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  ProcessSellListAndLoadTable(data: any) {
    if (data == undefined || data == null) {
      return;
    }
    var RawSellList = data;
    var SellList: any[] = [];
    var alreadyExists = false;

    RawSellList.forEach((item: any) => {
      alreadyExists = false;
      var item_outer = item;
      if (
        item_outer.OrderStatus == false &&
        this.scientificToDecimal(item_outer.PendingVolume) > 0
      ) {
        // SellList.push(item_outer);
        SellList.forEach((item, index) => {
          var item_inner = item;

          if (
            this.scientificToDecimal(item_inner.Rate) ==
            this.scientificToDecimal(item_outer.Rate)
          ) {
            item_inner.Volume =
              this.scientificToDecimal(item_inner.Volume) +
              this.scientificToDecimal(item_outer.PendingVolume);
            alreadyExists = true;
          }
        });

        if (!alreadyExists) {
          SellList.push({
            Volume: this.scientificToDecimal(item_outer.PendingVolume),
            Rate: this.scientificToDecimal(item_outer.Rate),
            Class: this.scientificToDecimal(item_outer.Class)
          });
        }
      }
    });

    SellList.sort((a, b) => {
      return (
        this.scientificToDecimal(a.Rate) - this.scientificToDecimal(b.Rate)
      );
    });
    this.TotalRecordsSell = SellList;
  }

  scientificToDecimal(num: any) {
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
      var zero = '0',
        parts = String(num)
          .toLowerCase()
          .split('e'), // split into coeff and exponent
        e = parts.pop(), // store the exponential part
        l = Math.abs(Number(e)), // get the number of zeros
        sign = Number(e) / l,
        coeff_array = parts[0].split('.');
      if (sign === -1 || sign.toString() === '-') {
        num = zero + '.' + new Array(l).join(zero) + coeff_array.join('');
      } else {
        var dec = coeff_array[1];
        if (dec) l = l - dec.length;
        num = coeff_array.join('') + new Array(l + 1).join(zero);
      }
    }

    return num;
  }

  ShowToastrAlert(heading: string, msg: string, type: string) {
    // Swal(heading, msg , type);
    if (type === 'error') {
      this.toastr.error(msg, heading);
    } else if (type === 'success') {
      this.toastr.success(msg, heading);
    } else if (type === 'info') {
      this.toastr.info(msg, heading);
    } else if (type === 'warning') {
      this.toastr.warning(msg, heading);
    }
  }

  // GetMyOrderHistory(coin: any) {
  //
  //   .append("Content-Type", "application/json");
  //   // .append("coinname_", code);
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get(this._baseUrl + "order/my-order-history/" +  coin, {
  //         //  .get(this._baseUrl + "market/get-trade-history/BTC_ETH", {
  //         :
  //       })
  //       .map(response => response.json())
  //       .subscribe(result => {
  //         resolve(result);
  //         console.log(result);
  //       }),
  //       err => {
  //         reject(err);
  //       };
  //   });
  // }




  GetKycDetail() {

    return new Promise<any>((resolve, reject) => {
      this._getApi(this._baseUrl + 'api/GOKYC_Get_Kyc_Form',)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }

  PostKycDetail(Bearer: any, Data: any, level: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/GOKYC_Submit_KYC_Form?level=' + level, Data,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }













  // _Getpagecontent() {

  //   return new Promise<any>((resolve, reject) => {
  //     this._getApi('api/get_page_n_content', )
  //       .then((result: any) => {
  //         resolve(result);
  //       }, (res: any) => {
  //       reject(res);
  //     });
  //   });
  // }
  DepthChart_data(SendDepthInfo: any) {

    return new Promise<any>((resolve, reject) => {
      this._getApi(this._baseUrl + 'market/depth?symbol=' + SendDepthInfo.symbol + '&limit=' + SendDepthInfo.limit,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }

  public GetWalletBalanceByCurrency(currencyName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const bearertoken = localStorage.getItem('Authorization');
      if (bearertoken === undefined || bearertoken == null || bearertoken.toString().trim().length < 20) {
        resolve([{
          balance: 0
        }]);
        return;
      }
      // currencyName = 'BTC';
      const payload = {
        currency: currencyName,
        // timestamp: Math.floor(new Date().getTime() / 1000),
        // recvWindow: 10000
      };
      // console.log('payload', payload);
      // this._GenerateHmac_Post(payload)
      this._getwalletbalance(payload, 'Bearer ' +
        bearertoken).then((getwalletbalance_result: any) => {
          if (getwalletbalance_result.status === 'Success') {
            resolve(getwalletbalance_result.data);
          } else {
            reject(getwalletbalance_result.data);
          }
        });
    });
  }

  public GetFiatPriceToUSD(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getFiatPrice().then((_result: any) => {
        resolve(_result);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  getFiatPrice() {
    return new Promise((resolve, reject) => {
      this._getApi('fiat-prices', true).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }


  requestRenewToken() {
    return new Promise((resolve, reject) => {
      this._PostApi('Customer/renew-token', '').then((res: any) => {
        resolve(res);
        localStorage.setItem('Authorization', res.access_token);
        this.authService.userSubject.next(res.access_token);
        this.startRefreshTokenTimer();
        this._GetuserProfile();
      }, (res: any) => {
        reject(res);
      });
    });
  }


  startRefreshTokenTimer() {
    clearInterval(this.refreshTokenTimeout);
    if (this.authService.isAuthorized()) {
      this.refreshTokenTimeout = setInterval(() => this.requestRenewToken(), 300000);
    }
  }


  Fiat_PG_Deposit_Request(payload: any, Bearer: any) {

    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/Add_Fiat_PG_Deposit_Request', payload,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }







  TradeHistory_Grouped(ordersHistory: any, Bearer: any) {


    return new Promise((resolve, reject) => {
      this._getApi(this._baseUrl + 'api/TradeHistory_Grouped?' + ordersHistory,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }


  DepositCurrCurrency(val: any) {
    this.DepositCurrentCurrency.next(val);
  }

  WithdrawCurrCurrency(val: any) {
    this.WithdrawCurrentCurrency.next(val);
  }

  _SelectedFiatCurrency(curr: any) {
    this.CurrentFiatCurr.next(curr);
  }

  GenerateS3_PutUrl(Bearer: any) {

    return new Promise((resolve, reject) => {
      this._getApi(this._baseUrl + 'api/get_s3_upload_url',)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }

  Simplex_Quote(currencydata: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/simplex_get_quote', currencydata,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  Simplex_History(datas: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/get_insta_trades/simplex', datas,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  Simplex_Payment(currencydata: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/simplex_payment', currencydata,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  Get_Exchange_Token(Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._getApi(this._baseUrl + 'api/convert_exchange_token?',)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  Post_Exchange_Token(currencydata: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/convert_exchange_token', currencydata,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  Withdrawalconfirmation(OTP: any) {

    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/RequestWithdrawConfirmation', OTP,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }



  AadharSend(Details: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/indian-kyc-verification', Details,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  AadharPanOTP(OTP: any, Bearer: any) {


    return new Promise<any>((resolve, reject) => {
      this._PostApi(this._baseUrl + 'api/aadhaar-otp-verification', OTP,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  IndianKYCstatus(Bearer: any) {

    return new Promise<any>((resolve, reject) => {
      this._getApi(this._baseUrl + 'api/indian-get-kyc-status',)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  GetTradingDiscount() {
    return new Promise((resolve, reject) => {
      this._getApi('wallet/volume-discount-tiers').then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  requestEstimatePNL() {
    return new Promise((resolve, reject) => {
      this._getApi('wallet/estimate-pnl').then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  Get_BlockDetails(request: any) {
    return new Promise((resolve, reject) => {
      this._getApi('Customer/block-account/' + request).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }
  saveFavPair(payload: any, Bearer: any) {
    return new Promise<any>((resolve, reject) => {
      this._PostApi('Customer/customer-favourite-pairs', payload,)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }
  GetFavPair(Bearer: any) {
    return new Promise<any>((resolve, reject) => {
      this._getApi('Customer/customer-favourite-pairs',)
        .then((result: any) => {
          resolve(result);
        }, (res: any) => {
          reject(res);
        });
    });
  }

  requestRiskRules(payload:any) {
    return new Promise((resolve, reject) => {
      this._getApi('customer/risk-rules/'+payload).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  async getRiskRules(isRedirect:boolean = true) {
    let result: any = await this.requestRiskRules(this.languageService.language?.value?.code).catch((err) => { });
    if (result == undefined || result == null) {
      return;
    }

    if (!_.isEmpty(result)) {
      this.addonifo.next(result);
      this.router.navigate(['/addon-info'])
    } else {
      if (isRedirect) {
        this.router.navigate(['/markets']);
      }
    }
  }

  Get_subInfo(request: any) {
    return new Promise((resolve, reject) => {
      this._getApi('subaccount/sub-acc-info/' + request).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }
  Accept_subInfo(request: any) {
    return new Promise((resolve, reject) => {
      this._getApi('subaccount/accept/' + request).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }
  Reject_subInfo(request: any) {
    return new Promise((resolve, reject) => {
      this._getApi('subaccount/reject/' + request).then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  requestAnnouncements() {
    return new Promise((resolve, reject) => {
      this._getApi('announcements').then((res: any) => {
        resolve(res);
        this.announceNotify.next(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  requestReadNotification(payload: any) {
    return new Promise((resolve, reject) => {
      this._PostApi('notification/set-read/'+payload,'').then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
    });
  }

  setMarketPair(data: any) {
    this.currentMarketPair.next(data);
  }


  triggerInfoModal(status: boolean) {
    this.infoModalStatus.next(status);
  }

  getThemeName(data: any) {
    this.currentTheme.next(data);
  }

  getPricePrecision(data: any) {
    this.pricePrecision.next(data);
  }

  getshowKycRequiredModal(data: boolean) {
    this.showKycRequiredModal.next(data);
  }

}






